import Orders from "pages/orders";
import OrderView from "pages/orders/view";
import OrderEdit from "pages/orders/edit";
import OrderReviews from "pages/orders/reviews";

const OrderRoutes = [
    {
        path: "/orders",
        component: Orders,
        private: true,
        privateRoles: [0, 1],
    },
    {
        path: "/orders/:id/view",
        component: OrderView,
        private: true,
        privateRoles: [0, 1],
    },
    {
        path: "/orders/:id/edit",
        component: OrderEdit,
        private: true,
        privateRoles: [0, 1],
    },
    {
        path: "/orders/reviews",
        component: OrderReviews,
        private: true,
        privateRoles: [0, 1],
    },
];

export default OrderRoutes;
