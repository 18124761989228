import { useState, useEffect } from "react";
import {
    Header,
    Breadcrumb,
    IconButton,
    Table,
    Pagination,
    Button,
} from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import axios from "utils/axios";
import { useRouter } from "utils/hooks";

export default function Admins() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [admin, setAdmin] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [admins, setAdmins] = useState([]);

    /**
     * If Change Admin and Limit
     */
    useEffect(() => {
        fetchAdmins(limit, admin > 1 ? (admin - 1) * limit : 0);
    }, [admin, limit]);

    /**
     * Fetch Admins
     * @param limit
     * @param offset
     */
    const fetchAdmins = async (limit, offset) => {
        setLoading(true);
        const response = await axios.get(
            `/admins?limit=${limit}&offset=${offset}`
        );
        setAdmins(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Remove Admin
     * @param id
     */
    const removeAdmin = async (id) => {
        if (window.confirm("Bunu etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.delete(`/admins/${id}`);
                fetchAdmins(limit, admin > 1 ? (admin - 1) * limit : 0);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() => router.push(`admins/${rowData.id}/edit`)}
                    icon={<Edit />}
                />
                <IconButton
                    appearance="subtle"
                    onClick={() => removeAdmin(rowData.id)}
                    icon={<Trash />}
                />
            </Table.Cell>
        );
    };

    /**
     * Get Role Name
     * @param {*} role
     * @returns
     */
    const getRoleName = (role) => {
        if (role === 0) {
            return "Admin";
        } else if (role === 1) {
            return "Menecer";
        } else if (role === 2) {
            return "Dizayner";
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Adminlər</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <Button
                        appearance="primary"
                        onClick={() => router.push("admins/create")}
                    >
                        Əlavə et
                    </Button>
                </div>
                <Table
                    height={400}
                    rowHeight={60}
                    data={admins}
                    loading={loading}
                >
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Adı</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.name}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => getRoleName(rowData.role)}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "adminr"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activeAdmin={admin}
                        onChangeAdmin={(admin) =>
                            setAdmin(admin > 0 ? admin : 1)
                        }
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setAdmin(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
