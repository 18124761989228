import { useCallback, useEffect, useState } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Loader,
    Form,
    Schema,
    SelectPicker,
} from "rsuite";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import axios from "utils/axios";
import { showToaster, updateValueByKey } from "utils/utils";

export default function AdminEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(null);
    const [admin, setAdmin] = useState(null);

    /**
     * Roles
     */
    const roles = [
        {
            label: "Admin",
            value: 0,
        },
        {
            label: "Menecer",
            value: 1,
        },
        {
            label: "Dizayner",
            value: 2,
        },
    ];

    /**
     * Fetch Admin
     */
    const fetchAdmin = useCallback(async () => {
        try {
            const response = await axios.get(`/admins/${id}`);
            setAdmin(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setAdmin(undefined);
        }
    }, [id]);

    /**
     * First Open Admin
     */
    useEffect(() => {
        fetchAdmin();
    }, [fetchAdmin]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.StringType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
        email: Schema.Types.StringType()
            .isRequired("Bu xananın doldurulması məcburidir.")
            .isEmail("Format düzgün deyil"),
        role: Schema.Types.NumberType("Rəqəm olmalıdır.").isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/admins/${id}`, admin);
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admins">Adminlər</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                {admin === undefined && <NotFound />}
                {admin && (
                    <Form
                        fluid
                        onSubmit={onSubmit}
                        formValue={admin}
                        model={formModel}
                    >
                        <InputField
                            type="text"
                            name="name"
                            label="Ad, Soyad"
                            onChange={(value) =>
                                updateValueByKey(admin, setAdmin, "name", value)
                            }
                        />
                        <InputField
                            type="email"
                            name="email"
                            label="Email"
                            onChange={(value) =>
                                updateValueByKey(
                                    admin,
                                    setAdmin,
                                    "email",
                                    value
                                )
                            }
                        />
                        <InputField
                            autoComplete="new-password"
                            type="password"
                            label="Şifrə"
                            onChange={(value) =>
                                updateValueByKey(
                                    admin,
                                    setAdmin,
                                    "password",
                                    value
                                )
                            }
                        />
                        <InputField
                            accepter={SelectPicker}
                            name="role"
                            label="Role"
                            data={roles}
                            onChange={(value) =>
                                updateValueByKey(
                                    admin,
                                    setAdmin,
                                    "role",
                                    parseInt(value) || 0
                                )
                            }
                        />
                        <SubmitButton text="Yadda saxla" />
                    </Form>
                )}
                {loading && (
                    <Loader
                        style={{ zIndex: 99 }}
                        size="md"
                        backdrop
                        center
                        content={loadingText || "Zəhmət olmasa gözləyin..."}
                    />
                )}
            </Content>
        </>
    );
}
