import { useContext, createContext, useState, useEffect } from "react";
import axios from "utils/axios";

const authContext = createContext();

export function ProvideAuth({
    children
}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [reqInfo, setReqInfo] = useState(false);

    /**
     * Login
     * @param {*} email 
     * @param {*} password 
     * @param {*} cb 
     */
    const signin = (email, password, cb) => {
        axios.post('/auth/login', {
            email: email,
            password: password
        }).then(res => {
            localStorage.setItem('access_token', res.data.token);
            info(res.data.token);
            cb(res);
        }).catch(err => {
            cb(err.response.data);
        });
    };

    /**
     * Logout
     */
    const signout = () => {
        const token = localStorage.getItem('access_token');
        let options = {};
        if (token) {
            options = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        }
        axios.get('/auth/logout', options).then(res => {
            setUser(null);
            localStorage.removeItem('access_token');
        });
    };
    
    /**
     * Get User Information
     * @param {*} token 
     */
    const info = (token = null) => {
        let options = {};
        if (token) {
            options = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
        }
        axios.get('/auth/info', options).then(res => {
            setUser(res.data.data);
            setReqInfo(true);
        }).catch(() => {
            setUser(null);
            setReqInfo(true);
        });
    };

    /**
     * First Open Page
     */
    useEffect(() => {
        info();
    }, []);

    /**
     * Return
     */
    return {
        user,
        reqInfo,
        signin,
        signout
    };
}

export function useAuth() {
    return useContext(authContext);
}
