import Variants from "pages/variants";
import VariantCreate from "pages/variants/create";
import VariantEdit from "pages/variants/edit";

const VariantRoutes = [
    {
        path: "/variants",
        component: Variants,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/variants/:id/edit",
        component: VariantEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/variants/create",
        component: VariantCreate,
        private: true,
        privateRoles: [0],
    },
];

export default VariantRoutes;
