import Promotions from "pages/promotions";
import PromotionCreate from "pages/promotions/create";
import PromotionEdit from "pages/promotions/edit";

const PromotionRoutes = [
    {
        path: '/promotions',
        component: Promotions,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/promotions/:id/edit',
        component: PromotionEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/promotions/create',
        component: PromotionCreate,
        private: true,
        privateRoles: [0],
    },
];

export default PromotionRoutes;