import Admins from "pages/admins";
import AdminCreate from "pages/admins/create";
import AdminEdit from "pages/admins/edit";

const AdminRoutes = [
    {
        path: '/admins',
        component: Admins,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/admins/:id/edit',
        component: AdminEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/admins/create',
        component: AdminCreate,
        private: true,
        privateRoles: [0],
    },
];

export default AdminRoutes;