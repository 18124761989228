import { useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema } from "rsuite";
import Compressor from "compressorjs";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import slug from "slug";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";

export default function PageCreate() {
    const initialValue = {
        name: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        content: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        url: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        row: 1,
    };
    const [loading, setLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [page, setPage] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "content", "url"];

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        content: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        row: Schema.Types.NumberType("Rəqəm olmalıdır.").isRequired("Bu xananın doldurulması məcburidir."),
    });

    /**
     * Initialize a markdown parser
     */
    const mdParser = new MarkdownIt();
    const onEditorImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.8,
                async success(result) {
                    const formData = new FormData();
                    formData.append("file", result, result.name);
                    const uploaded = await axios.post("/pages/image/editor/upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    resolve(`${process.env.REACT_APP_SITE_URL}/storage/pages/editor/${uploaded.data.data}`);
                },
                error(err) {
                    showToaster("danger", err.message);
                    reject(false);
                },
            });
        });
    };

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/pages`, page);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setPage(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/pages">Səhifələr</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={page}
                    model={formModel}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{
                                display: selectedLang === lang ? "block" : "none",
                                marginBottom: 24,
                            }}
                        >
                            <InputField
                                type="text"
                                name={`name-${lang}`}
                                label="Adı"
                                value={page.name[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                onChange={(value) =>
                                    updateValueByKeys(page, setPage, {
                                        name: {
                                            ...page.name,
                                            [lang]: value,
                                        },
                                        url: {
                                            ...page.url,
                                            [lang]: slug(value),
                                        },
                                    })
                                }
                            />
                            <div className="rs-form-control-wrapper">
                                <MdEditor
                                    style={{ height: 500, marginBottom: 24 }}
                                    name={`content-${lang}`}
                                    value={page.content[lang]}
                                    renderHTML={(text) => mdParser.render(text)}
                                    onImageUpload={onEditorImageUpload}
                                    onChange={(data, e) =>
                                        updateLangValueByKey(page, setPage, "content", lang, data.text)
                                    }
                                />
                                <Form.ErrorMessage
                                    show={getLangFormErrMessage(formErrors, "content", lang) ? true : false}
                                    placement="bottomStart"
                                >
                                    {getLangFormErrMessage(formErrors, "content", lang)}
                                </Form.ErrorMessage>
                            </div>
                            <InputField
                                type="text"
                                name={`url-${lang}`}
                                label="URL"
                                value={page.url[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                onChange={(value) => updateLangValueByKey(page, setPage, "url", lang, value)}
                            />
                        </div>
                    ))}
                    <InputField
                        type="number"
                        name="row"
                        label="Sıra"
                        onChange={(value) => updateValueByKey(page, setPage, "row", parseInt(value) || 0)}
                    />
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
