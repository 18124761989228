import { Icon } from '@rsuite/icons';

const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
        <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="1em" height="1em" fill="currentColor">
            <path d={svgPathData}></path>
        </svg>
    );
};

const FaIcon = ({icon}) => <Icon as={FaSvgIcon} faIcon={icon} />;

export default FaIcon;