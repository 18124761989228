import { useCallback, useEffect, useState } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Nav,
    Loader,
    Form,
    Schema,
    Message,
    TagInput,
    Uploader,
    List,
    IconButton,
    SelectPicker,
} from "rsuite";
import {
    Image as IconImage,
    Dragable as IconDragable,
    ArrowDown as IconArrowDown,
    ArrowUp as IconArrowUp,
} from "@rsuite/icons";
import Compressor from "compressorjs";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
    findNestedObj,
} from "utils/utils";
import slug from "slug";

export default function CategoryEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(null);
    const [selectedLang, setSelectedLang] = useState("az");
    const [category, setCategory] = useState(null);
    const [parentCategories, setParentCategories] = useState([]);
    const [visibleParentCats, setVisibleParentCats] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "url"];
    const UPLOADERS = [
        {
            title: "İkon",
            type: "icon",
            width: 50,
            height: 50,
        },
        {
            title: "Şəkil",
            type: "image",
            width: 300,
            height: 170,
        },
    ];

    /**
     * Fetch Category
     */
    const fetchCategory = useCallback(async () => {
        try {
            const response = await axios.get(`/categories/${id}`);
            setCategory(response.data.data);
            setVisibleParentCats(
                Object.fromEntries(
                    response.data.data.subcategories
                        .filter((item) => item.collection === 0)
                        .map((item) => [item.value, false])
                )
            );
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setCategory(undefined);
        }
    }, [id]);

    /**
     * Fetch Categories
     */
    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get("/categories");
            setParentCategories(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.code,
                }))
            );
        } catch (error) {}
    }, []);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchCategory();
        fetchCategories();
    }, [fetchCategory, fetchCategories]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/categories/${id}`, category);
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     */
    const onRemoveImage = async (file, type = "image") => {
        try {
            await axios.post("/categories/image/remove", {
                file: file,
            });
            setCategory({
                ...category,
                [type]: "",
            });
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Sort Subcategory for Parent Category
     */
    const handleSortEndCategory = ({ oldIndex, newIndex }) => {
        setCategory((prvData) => {
            // Change Values
            const moveData = prvData.subcategories.splice(oldIndex, 1);
            const newData = [...prvData.subcategories];
            newData.splice(newIndex, 0, moveData[0]);

            // Sort Values
            const sortData = [];
            for (const category of newData.filter((item) => item.collection === 0)) {
                const subcategories = newData.filter((item) => item.parent_id === category.value);
                sortData.push(category);
                sortData.push(...subcategories);
            }

            // Return Data
            return {
                ...prvData,
                subcategories: sortData,
            };
        }, []);
    };

    /**
     * ListSortable
     * @param {*} categories
     * @returns
     */
    const ListSortable = ({ categories }) => {
        return (
            <List
                sortable
                onSort={(oldIndex, newIndex) => handleSortEndCategory(oldIndex, newIndex)}
                style={{ marginBottom: 15 }}
            >
                {categories.map((item, index) => (
                    <div key={index} style={{ display: "flex", alignItems: "center" }}>
                        {item.collection === 0 && (
                            <IconButton
                                icon={!visibleParentCats[item.value] ? <IconArrowDown /> : <IconArrowUp />}
                                onClick={
                                    item.collection === 0
                                        ? () =>
                                              setVisibleParentCats({
                                                  ...visibleParentCats,
                                                  [item.value]: !visibleParentCats[item.value],
                                              })
                                        : () => null
                                }
                            />
                        )}
                        <List.Item
                            key={index}
                            index={index}
                            collection={item.collection}
                            style={{
                                flex: 1,
                                marginLeft: item.collection === 0 ? 10 : 0,
                                display:
                                    item.collection === 0 ||
                                    (item.collection !== 0 && visibleParentCats[item.parent_id])
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <span
                                style={{
                                    flex: 1,
                                    fontWeight: item.collection === 0 ? "bold" : "normal",
                                }}
                            >
                                {findNestedObj(categories, "value", item.value).label}
                            </span>
                            <IconDragable />
                        </List.Item>
                    </div>
                ))}
            </List>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/categories">Kateqoriyalar</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                {category === undefined && <NotFound />}
                {category && (
                    <>
                        <Nav
                            appearance="tabs"
                            activeKey={selectedLang}
                            onSelect={(key) => setSelectedLang(key)}
                            style={{ marginBottom: 20 }}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <Nav.Item key={i} eventKey={lang}>
                                    {lang.toUpperCase()}
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Form
                            fluid
                            onSubmit={onSubmit}
                            onError={(errors) =>
                                onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                            }
                            formValue={category}
                            model={formModel}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <div
                                    key={i}
                                    id={`tabs-${lang}`}
                                    style={{
                                        display: selectedLang === lang ? "block" : "none",
                                        marginBottom: 24,
                                    }}
                                >
                                    <InputField
                                        type="text"
                                        name={`name-${lang}`}
                                        value={category.name[lang]}
                                        label="Adı"
                                        errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                        onChange={(value) =>
                                            updateValueByKeys(category, setCategory, {
                                                name: {
                                                    ...category.name,
                                                    [lang]: value,
                                                },
                                                url: {
                                                    ...category.url,
                                                    [lang]: slug(value),
                                                },
                                            })
                                        }
                                    />
                                    <InputField
                                        type="text"
                                        name={`url-${lang}`}
                                        label="URL"
                                        value={category.url[lang]}
                                        errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                        onChange={(value) =>
                                            updateLangValueByKey(category, setCategory, "url", lang, value)
                                        }
                                    />
                                    {UPLOADERS.map((item, index) => (
                                        <div key={index} className="rs-form-control-wrapper">
                                            <Form.Group>
                                                <Form.ControlLabel>
                                                    {item.title} <br />
                                                    <strong>
                                                        Tövsiyyə olunan ölçülər:
                                                        {item.width}x{item.height}
                                                    </strong>
                                                </Form.ControlLabel>
                                                <Uploader
                                                    action={`${process.env.REACT_APP_API_URL}/categories/image/upload`}
                                                    data={{
                                                        type: item.type,
                                                    }}
                                                    headers={{
                                                        Authorization: localStorage.getItem("access_token")
                                                            ? `Bearer ${localStorage.getItem("access_token")}`
                                                            : "",
                                                    }}
                                                    accept="image/*"
                                                    listType="picture"
                                                    fileListVisible={false}
                                                    onRemove={(file) => onRemoveImage(file.name, item.type)}
                                                    onUpload={(file) => {
                                                        setUploadLoading(true);
                                                        if (category[item.type]) {
                                                            onRemoveImage(category[item.type], item.type);
                                                        }
                                                    }}
                                                    onSuccess={(response, file) => {
                                                        file.name = response.data;
                                                        setCategory({
                                                            ...category,
                                                            [item.type]: response.data,
                                                        });
                                                        setUploadLoading(false);
                                                    }}
                                                    onError={() => {
                                                        setCategory({
                                                            ...category,
                                                            [item.type]: "",
                                                        });
                                                        setUploadLoading(false);
                                                    }}
                                                    shouldUpload={(file) => {
                                                        if (file.status !== "inited") {
                                                            return false;
                                                        }
                                                        return new Promise((resolve, reject) => {
                                                            new Compressor(file.blobFile, {
                                                                quality: 0.8,
                                                                success(result) {
                                                                    file.blobFile = result;
                                                                    resolve(true);
                                                                },
                                                                error(err) {
                                                                    showToaster("danger", err.message);
                                                                    reject(false);
                                                                },
                                                            });
                                                        });
                                                    }}
                                                    draggable
                                                >
                                                    <button
                                                        type="button"
                                                        style={{
                                                            width: 150,
                                                            height: 150,
                                                        }}
                                                    >
                                                        {uploadLoading && <Loader backdrop center />}
                                                        {category[item.type] ? (
                                                            <img
                                                                src={`${
                                                                    process.env.REACT_APP_SITE_URL
                                                                }/storage/categoryimages/${category[item.type]}`}
                                                                width="100%"
                                                                alt="category"
                                                            />
                                                        ) : (
                                                            <IconImage
                                                                style={{
                                                                    fontSize: "5em",
                                                                }}
                                                            />
                                                        )}
                                                    </button>
                                                </Uploader>
                                            </Form.Group>
                                        </div>
                                    ))}
                                    <hr />
                                    <h5>SEO</h5>
                                    <hr />
                                    <InputField
                                        name={`seo_title-${lang}`}
                                        label="Title"
                                        value={
                                            category.seo_title && category.seo_title[lang]
                                                ? category.seo_title[lang]
                                                : ""
                                        }
                                        errorMessage={getLangFormErrMessage(formErrors, "seo_title", lang)}
                                        onChange={(value) =>
                                            updateLangValueByKey(category, setCategory, "seo_title", lang, value)
                                        }
                                    />
                                    <InputField
                                        textarea={true}
                                        rows={5}
                                        name={`seo_description-${lang}`}
                                        label="Description"
                                        value={
                                            category.seo_description && category.seo_description[lang]
                                                ? category.seo_description[lang]
                                                : ""
                                        }
                                        errorMessage={getLangFormErrMessage(formErrors, "seo_description", lang)}
                                        onChange={(value) =>
                                            updateLangValueByKey(category, setCategory, "seo_description", lang, value)
                                        }
                                    />
                                    <InputField
                                        accepter={TagInput}
                                        name={`seo_keyword-${lang}`}
                                        label="Açar sözləri"
                                        value={
                                            category.seo_keywords && category.seo_keywords[lang]
                                                ? category.seo_keywords[lang].split(", ")
                                                : []
                                        }
                                        errorMessage={getLangFormErrMessage(formErrors, "seo_keywords", lang)}
                                        onChange={(value) =>
                                            updateLangValueByKey(
                                                category,
                                                setCategory,
                                                "seo_keywords",
                                                lang,
                                                value.join(", ")
                                            )
                                        }
                                    />
                                    <hr />
                                </div>
                            ))}
                            <InputField
                                accepter={SelectPicker}
                                data={parentCategories}
                                virtualized
                                name="parent_id"
                                label="Üst kateqoriya"
                                onChange={(value) => updateValueByKey(category, setCategory, "parent_id", value)}
                            />
                            {!category.parent_id && (
                                <InputField
                                    type="text"
                                    name="product_bar_code"
                                    label="Məhsul bar kodu"
                                    onChange={(value) =>
                                        updateValueByKey(category, setCategory, "product_bar_code", value)
                                    }
                                />
                            )}

                            {/* Stock Limit */}
                            <Message style={{ marginBottom: 15 }}>
                                <strong>Stok limiti:</strong> Göstərilən saydan yuxarılar görünəcək. 0 olduqda üstdəki
                                kateqoriyanın stok limiti götürülür
                            </Message>
                            <InputField
                                type="number"
                                name="product_stock_limit"
                                label="Stok limiti"
                                onChange={(value) =>
                                    updateValueByKey(category, setCategory, "product_stock_limit", parseInt(value) || 0)
                                }
                            />

                            {/* Sortable Subcategories */}
                            {!category.parent_id && (
                                <>
                                    <hr />
                                    <Message style={{ marginBottom: 15 }}>
                                        <strong>Alt kateqoriya sıralaması:</strong> Burda sıralanan alt kateqoriyalar,
                                        ən üst kateqoriyada sıralamaya uyğun olaraq sıralanacaq
                                    </Message>
                                    <ListSortable categories={category.subcategories} />
                                </>
                            )}

                            {/* Save Button */}
                            <SubmitButton text="Yadda saxla" />
                        </Form>
                    </>
                )}
                {loading && <Loader size="md" backdrop center content={loadingText || "Zəhmət olmasa gözləyin..."} />}
            </Content>
        </>
    );
}
