import { useState, useEffect, useCallback } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Nav,
    Loader,
    Form,
    Schema,
    Message,
    TagInput,
    Uploader,
    SelectPicker,
} from "rsuite";
import { Image as IconImage } from "@rsuite/icons";
import Compressor from "compressorjs";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";
import slug from "slug";

export default function CategoryCreate() {
    const initialValue = {
        name: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        url: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        icon: "",
        image: "",
        seo_title: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        seo_description: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        seo_keywords: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        parent_id: "",
        product_bar_code: "",
        product_stock_limit: 0,
    };
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [category, setCategory] = useState(initialValue);
    const [parentCategories, setParentCategories] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "url"];
    const UPLOADERS = [
        {
            title: "İkon",
            type: "icon",
            width: 50,
            height: 50,
        },
        {
            title: "Şəkil",
            type: "image",
            width: 300,
            height: 170,
        },
    ];

    /**
     * Fetch Categories
     */
    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get("/categories");
            setParentCategories(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.id,
                }))
            );
        } catch (error) {}
    }, []);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/categories`, category);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            fetchCategories();
            setCategory(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     */
    const onRemoveImage = async (file, type = "image") => {
        try {
            await axios.post("/categories/image/remove", {
                file: file,
            });
            setCategory({
                ...category,
                [type]: "",
            });
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/categories">Kateqoriyalar</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tək</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={category}
                    model={formModel}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{
                                display: selectedLang === lang ? "block" : "none",
                                marginBottom: 24,
                            }}
                        >
                            <InputField
                                type="text"
                                name={`name-${lang}`}
                                label="Adı"
                                value={category.name[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                onChange={(value) =>
                                    updateValueByKeys(category, setCategory, {
                                        name: {
                                            ...category.name,
                                            [lang]: value,
                                        },
                                        url: {
                                            ...category.url,
                                            [lang]: slug(value),
                                        },
                                    })
                                }
                            />
                            <InputField
                                type="text"
                                name={`url-${lang}`}
                                label="URL"
                                value={category.url[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                onChange={(value) => updateLangValueByKey(category, setCategory, "url", lang, value)}
                            />
                            {UPLOADERS.map((item, index) => (
                                <div key={index} className="rs-form-control-wrapper">
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            {item.title} <br />
                                            <strong>
                                                Tövsiyyə olunan ölçülər: {item.width}x{item.height}
                                            </strong>
                                        </Form.ControlLabel>
                                        <Uploader
                                            action={`${process.env.REACT_APP_API_URL}/categories/image/upload`}
                                            data={{
                                                type: item.type,
                                            }}
                                            headers={{
                                                Authorization: localStorage.getItem("access_token")
                                                    ? `Bearer ${localStorage.getItem("access_token")}`
                                                    : "",
                                            }}
                                            accept="image/*"
                                            listType="picture"
                                            fileListVisible={false}
                                            onRemove={(file) => onRemoveImage(file.name, item.type)}
                                            onUpload={(file) => {
                                                setUploadLoading(true);
                                                if (category[item.type]) {
                                                    onRemoveImage(category[item.type], item.type);
                                                }
                                            }}
                                            onSuccess={(response, file) => {
                                                file.name = response.data;
                                                setCategory({
                                                    ...category,
                                                    [item.type]: response.data,
                                                });
                                                setUploadLoading(false);
                                            }}
                                            onError={() => {
                                                setCategory({
                                                    ...category,
                                                    [item.type]: "",
                                                });
                                                setUploadLoading(false);
                                            }}
                                            shouldUpload={(file) => {
                                                if (file.status !== "inited") {
                                                    return false;
                                                }
                                                return new Promise((resolve, reject) => {
                                                    new Compressor(file.blobFile, {
                                                        quality: 0.8,
                                                        success(result) {
                                                            file.blobFile = result;
                                                            resolve(true);
                                                        },
                                                        error(err) {
                                                            showToaster("danger", err.message);
                                                            reject(false);
                                                        },
                                                    });
                                                });
                                            }}
                                            draggable
                                        >
                                            <button
                                                type="button"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                }}
                                            >
                                                {uploadLoading && <Loader backdrop center />}
                                                {category[item.type] ? (
                                                    <img
                                                        src={`${
                                                            process.env.REACT_APP_SITE_URL
                                                        }/storage/categoryimages/${category[item.type]}`}
                                                        width="100%"
                                                        alt="category"
                                                    />
                                                ) : (
                                                    <IconImage
                                                        style={{
                                                            fontSize: "5em",
                                                        }}
                                                    />
                                                )}
                                            </button>
                                        </Uploader>
                                    </Form.Group>
                                </div>
                            ))}
                            <hr />
                            <h5>SEO</h5>
                            <hr />
                            <InputField
                                name={`seo_title-${lang}`}
                                label="Title"
                                value={category.seo_title && category.seo_title[lang] ? category.seo_title[lang] : ""}
                                errorMessage={getLangFormErrMessage(formErrors, "seo_title", lang)}
                                onChange={(value) =>
                                    updateLangValueByKey(category, setCategory, "seo_title", lang, value)
                                }
                            />
                            <InputField
                                textarea={true}
                                rows={5}
                                name={`seo_description-${lang}`}
                                label="Description"
                                value={
                                    category.seo_description && category.seo_description[lang]
                                        ? category.seo_description[lang]
                                        : ""
                                }
                                errorMessage={getLangFormErrMessage(formErrors, "seo_description", lang)}
                                onChange={(value) =>
                                    updateLangValueByKey(category, setCategory, "seo_description", lang, value)
                                }
                            />
                            <InputField
                                accepter={TagInput}
                                name={`seo_keyword-${lang}`}
                                label="Açar sözləri"
                                value={
                                    category.seo_keywords && category.seo_keywords[lang]
                                        ? category.seo_keywords[lang].split(", ")
                                        : []
                                }
                                errorMessage={getLangFormErrMessage(formErrors, "seo_keywords", lang)}
                                onChange={(value) =>
                                    updateLangValueByKey(category, setCategory, "seo_keywords", lang, value.join(", "))
                                }
                            />
                            <hr />
                        </div>
                    ))}
                    <InputField
                        accepter={SelectPicker}
                        data={parentCategories}
                        virtualized
                        name="parent_id"
                        label="Üst kateqoriya"
                        onChange={(value) => updateValueByKey(category, setCategory, "parent_id", value)}
                    />
                    {!category.parent_id && (
                        <InputField
                            type="text"
                            name="product_bar_code"
                            label="Məhsul bar kodu"
                            onChange={(value) => updateValueByKey(category, setCategory, "product_bar_code", value)}
                        />
                    )}
                    {/* Stock Limit */}
                    <Message style={{ marginBottom: 15 }}>
                        <strong>Stok limiti:</strong> Göstərilən saydan yuxarılar görünəcək. 0 olduqda üstdəki
                        kateqoriyanın stok limiti götürülür
                    </Message>
                    <InputField
                        type="number"
                        name="product_stock_limit"
                        label="Stok limiti"
                        onChange={(value) =>
                            updateValueByKey(category, setCategory, "product_stock_limit", parseInt(value) || 0)
                        }
                    />

                    {/* Save Button */}
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
