import { useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema, Grid, Row, Col, IconButton } from "rsuite";
import { Trash, Plus } from "@rsuite/icons";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";

export default function VariantCreate() {
    const initialValue = {
        name: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        values: [],
    };
    const [loading, setLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [variant, setVariant] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name"];

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/variants`, variant);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setVariant(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/variants">Variantlar</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={variant}
                    model={formModel}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{ display: selectedLang === lang ? "block" : "none", marginBottom: 24 }}
                        >
                            <InputField
                                type="text"
                                name={`name-${lang}`}
                                label="Adı"
                                value={variant.name[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                onChange={(value) =>
                                    updateValueByKeys(variant, setVariant, {
                                        name: {
                                            ...variant.name,
                                            [lang]: value,
                                        },
                                    })
                                }
                            />
                            <hr />
                            <div id="values" style={{ marginBottom: 24 }}>
                                <h5>Seçimlər</h5>
                                <Grid fluid style={{ padding: 0, marginTop: 24 }}>
                                    {variant.values && variant.values.length > 0 ? (
                                        variant.values.map((value, i) => (
                                            <Row key={i} style={{ marginBottom: 24 }}>
                                                <Col xs={7}>
                                                    <InputField
                                                        type="text"
                                                        name={`name-${i}`}
                                                        label="Başlıq"
                                                        value={variant.values[i].name[lang]}
                                                        onChange={(value) => {
                                                            variant.values[i].name[lang] = value;
                                                            updateValueByKey(
                                                                variant,
                                                                setVariant,
                                                                "values",
                                                                variant.values
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    xs={3}
                                                    style={{
                                                        marginTop: 24,
                                                    }}
                                                >
                                                    <IconButton
                                                        icon={<Trash />}
                                                        onClick={() => {
                                                            const newValues = variant.values.filter(
                                                                (item) => item.id !== value.id || item.i !== value.i
                                                            );
                                                            variant.values = newValues;
                                                            updateValueByKey(
                                                                variant,
                                                                setVariant,
                                                                "values",
                                                                variant.values
                                                            );
                                                        }}
                                                    />
                                                    {variant.values.length === i + 1 && (
                                                        <IconButton
                                                            icon={<Plus />}
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                            onClick={() => {
                                                                variant.values.push({
                                                                    i:
                                                                        Math.max(
                                                                            ...variant.values
                                                                                .map((maxItem) => maxItem.i)
                                                                                .filter((maxItem) => maxItem),
                                                                            0
                                                                        ) + 1,
                                                                    name: Object.fromEntries(
                                                                        SUPPORTED_LANGUAGES.map((lang) => [lang, ""])
                                                                    ),
                                                                });
                                                                updateValueByKey(
                                                                    variant,
                                                                    setVariant,
                                                                    "values",
                                                                    variant.values
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <IconButton
                                            icon={<Plus />}
                                            onClick={() => {
                                                variant.values.push({
                                                    i: 0,
                                                    name: Object.fromEntries(
                                                        SUPPORTED_LANGUAGES.map((lang) => [lang, ""])
                                                    ),
                                                });
                                                updateValueByKey(variant, setVariant, "values", variant.values);
                                            }}
                                        />
                                    )}
                                </Grid>
                            </div>
                        </div>
                    ))}
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
