import { useState, useEffect } from "react";
import { Header, Breadcrumb, IconButton, Table, Pagination, Button, InputGroup, Input } from "rsuite";
import { Edit, Trash, Search } from "@rsuite/icons";
import axios from "utils/axios";
import { useRouter } from "utils/hooks";
import { delay } from "utils/utils";

export default function Categories() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        query: "",
    });
    const [categories, setCategories] = useState([]);

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchCategories(limit, page > 1 ? (page - 1) * limit : 0, filter);
    }, [page, limit, filter]);

    /**
     * Fetch Categories
     * @param limit
     * @param offset
     */
    const fetchCategories = async (limit, offset, filter) => {
        setLoading(true);
        const response = await axios.get(
            `/categories?limit=${limit}&offset=${offset}${filter.query ? `&query=${filter.query}` : ""}`
        );
        setCategories(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Remove Category
     * @param id
     */
    const removeCategory = async (id) => {
        if (window.confirm("Bunu etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.delete(`/categories/${id}`);
                fetchCategories(limit, page > 1 ? (page - 1) * limit : 0, filter);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() => router.push(`categories/${rowData[dataKey]}/edit`)}
                    icon={<Edit />}
                />
                <IconButton appearance="subtle" onClick={() => removeCategory(rowData[dataKey])} icon={<Trash />} />
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Kateqoriyalar</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <InputGroup size="md" inside style={{ width: 200, marginRight: 15 }}>
                        <Input
                            placeholder="Axtar..."
                            onChange={(value) => {
                                delay(() => {
                                    if (page > 1) {
                                        setPage(1);
                                    }
                                    setFilter({
                                        ...filter,
                                        query: value,
                                    });
                                }, 250);
                            }}
                        />
                        <InputGroup.Button>
                            <Search />
                        </InputGroup.Button>
                    </InputGroup>
                    <Button appearance="primary" onClick={() => router.push("categories/create")}>
                        Əlavə et
                    </Button>
                    {/* <Dropdown renderToggle={(props, ref) => <Button {...props} ref={ref} appearance="primary">Əlavə et <ArrowDown /></Button>}>
                        <Dropdown.Item onClick={() => router.push('categories/create')}>Tək</Dropdown.Item>
                        <Dropdown.Item onClick={() => router.push('categories/create/bulk')}>Toplu</Dropdown.Item>
                        <Dropdown.Item onClick={() => router.push('categories/create/bulk/keywords')}>Açar sözləri</Dropdown.Item>
                    </Dropdown> */}
                </div>
                <Table height={400} rowHeight={60} data={categories} loading={loading}>
                    <Table.Column width={50} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Adı</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.name.az}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell dataKey="id" />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "pager"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
