import { useCallback, useEffect, useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema } from "rsuite";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
} from "utils/utils";

export default function StoreEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(null);
    const [selectedLang, setSelectedLang] = useState("az");
    const [store, setStore] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "address"];

    /**
     * Fetch Store
     */
    const fetchStore = useCallback(async () => {
        try {
            const response = await axios.get(`/stores/${id}`);
            setStore(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setStore(undefined);
        }
    }, [id]);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchStore();
    }, [fetchStore]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        address: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        phone: Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/stores/${id}`, store);
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/stores">Mağazalarımız</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                {store === undefined && <NotFound />}
                {store && (
                    <>
                        <Nav
                            appearance="tabs"
                            activeKey={selectedLang}
                            onSelect={(key) => setSelectedLang(key)}
                            style={{ marginBottom: 20 }}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <Nav.Item key={i} eventKey={lang}>
                                    {lang.toUpperCase()}
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Form
                            fluid
                            onSubmit={onSubmit}
                            onError={(errors) =>
                                onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                            }
                            formValue={store}
                            model={formModel}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <div
                                    key={i}
                                    id={`tabs-${lang}`}
                                    style={{ display: selectedLang === lang ? "block" : "none", marginBottom: 24 }}
                                >
                                    <InputField
                                        type="text"
                                        name={`name-${lang}`}
                                        value={store.name[lang]}
                                        label="Adı"
                                        errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                        onChange={(value) => updateLangValueByKey(store, setStore, "name", lang, value)}
                                    />
                                    <InputField
                                        type="text"
                                        name={`address-${lang}`}
                                        label="Ünvan"
                                        value={store.address[lang]}
                                        errorMessage={getLangFormErrMessage(formErrors, "address", lang)}
                                        onChange={(value) =>
                                            updateLangValueByKey(store, setStore, "address", lang, value)
                                        }
                                    />
                                </div>
                            ))}
                            <InputField
                                type="text"
                                name="phone"
                                label="Telefon"
                                onChange={(value) => updateValueByKey(store, setStore, "phone", value)}
                            />
                            <SubmitButton text="Yadda saxla" />
                        </Form>
                    </>
                )}
                {loading && <Loader size="md" backdrop center content={loadingText || "Zəhmət olmasa gözləyin..."} />}
            </Content>
        </>
    );
}
