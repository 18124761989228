import Pages from "pages/pages";
import PageCreate from "pages/pages/create";
import PageEdit from "pages/pages/edit";

const PageRoutes = [
    {
        path: '/pages',
        component: Pages,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/pages/:id/edit',
        component: PageEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/pages/create',
        component: PageCreate,
        private: true,
        privateRoles: [0],
    },
];

export default PageRoutes;