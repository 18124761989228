import { useState, useEffect } from "react";
import {
    Header,
    Breadcrumb,
    IconButton,
    Table,
    Pagination,
    Button,
    SelectPicker,
    Modal,
    List,
    Loader,
    FlexboxGrid,
    Checkbox,
} from "rsuite";
import { Visible, Edit, Check, Close } from "@rsuite/icons";
import axios from "utils/axios";
import { showToaster, dateFormat } from "utils/utils";
import { PRODUCT_DEACTIVE_REASONS } from "appConstants";

export default function Orders() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        status: null,
    });
    const [orders, setOrders] = useState([]);
    const [acceptModalData, setAcceptModalData] = useState(null);
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [acceptModalLoading, setAcceptModalLoading] = useState(false);
    const [acceptModalCheckboxChecked, setAcceptModalCheckboxChecked] = useState([]);

    /**
     * Order Statuses
     */
    const ORDER_STATUSES = [
        { label: "Gözləmədədir", value: 0 },
        { label: "Hazırlanır", value: 1 },
        { label: "Çatdırılmadadır", value: 2 },
        { label: "Tamamlandı", value: 3 },
        { label: "Ləğv edildi", value: 4 },
    ];

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchOrders(limit, page > 1 ? (page - 1) * limit : 0, filter);
    }, [page, limit, filter]);

    /**
     * Fetch Orders
     * @param limit
     * @param offset
     * @param filter
     */
    const fetchOrders = async (limit, offset, filter) => {
        setLoading(true);
        const response = await axios.get(
            `/orders?limit=${limit}&offset=${offset}${filter.status !== null ? `&status=${filter.status}` : ""}`
        );
        setOrders(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Fetch Accept Modal Data
     * @param {integer} id
     */
    const fetchAcceptModalData = async (id) => {
        setAcceptModalLoading(true);
        setAcceptModalData(null);
        setAcceptModalCheckboxChecked([]);
        try {
            const response = await axios.get(`/orders/${id}`);
            setAcceptModalData(response.data.data);
            setAcceptModalLoading(false);
        } catch (err) {
            setAcceptModalLoading(false);
        }
    };

    /**
     * Accept Order
     * @param {integer} id
     */
    const acceptOrder = async (id) => {
        if (window.confirm("Bu əməliyyatı etmək istədiyinizə əminsiniz?")) {
            if (acceptModalCheckboxChecked.length === 0) {
                return showToaster("error", "Zəhmət olmasa ən az 1 məhsul seçin");
            }
            if (acceptModalCheckboxChecked.filter((item) => item.stock_id == null).length > 0) {
                return showToaster("error", "Zəhmət olmasa stokdan çıxılacaq mağazaları seçin");
            }
            try {
                await axios.post(`/orders/${id}`, {
                    status_type: 1,
                    acceptedProducts: acceptModalCheckboxChecked,
                });
                fetchOrders(limit, page > 1 ? (page - 1) * limit : 0, filter);
                setAcceptModalOpen(false);
                showToaster("success", "Sifariş təsdiqləndi");
            } catch (err) {
                showToaster("error", err.response.data.message);
            }
        }
    };

    /**
     * Cancel Order
     * @param {integer} id
     */
    const cancelOrder = async (id) => {
        if (window.confirm("Bu əməliyyatı etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.post(`/orders/${id}`, {
                    status_type: 4,
                });
                fetchOrders(limit, page > 1 ? (page - 1) * limit : 0, filter);
                showToaster("success", "Sifariş ləğv edildi");
            } catch (err) {
                showToaster("error", err.response.data.message);
            }
        }
    };

    /**
     * onChangeCheckboxAcceptModal
     * @param {integer} value
     */
    const onChangeCheckboxAcceptModal = (value) => {
        let checkedIDS = acceptModalCheckboxChecked;
        if (acceptModalCheckboxChecked.find((findItem) => findItem.id === value)) {
            checkedIDS = acceptModalCheckboxChecked.filter((item) => item.id !== value);
        } else {
            checkedIDS = [
                ...acceptModalCheckboxChecked,
                {
                    id: value,
                    stock_id: null,
                },
            ];
        }
        setAcceptModalCheckboxChecked([...checkedIDS]);
    };

    /**
     * onChangeStockAcceptModal
     * @param {integer} value
     * @param {integer} id
     */
    const onChangeStockAcceptModal = (value, id) => {
        const index = acceptModalCheckboxChecked.findIndex((findItem) => findItem.id === id);
        if (index > -1) {
            acceptModalCheckboxChecked[index].stock_id = value;
        }
        setAcceptModalCheckboxChecked([...acceptModalCheckboxChecked]);
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() => window.open(`orders/${rowData.id}/view`, "_blank")}
                    icon={<Visible />}
                />
                {rowData.status_type === 0 ? (
                    <>
                        <IconButton
                            appearance="subtle"
                            color="green"
                            onClick={() => {
                                setAcceptModalOpen(true);
                                fetchAcceptModalData(rowData.id);
                            }}
                            icon={<Check />}
                        />
                        <IconButton
                            appearance="subtle"
                            color="red"
                            onClick={() => cancelOrder(rowData.id)}
                            icon={<Close />}
                        />
                    </>
                ) : (
                    <IconButton
                        appearance="subtle"
                        onClick={() => window.open(`orders/${rowData.id}/edit`, "_blank")}
                        icon={<Edit />}
                    />
                )}
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Sifarişlər</Breadcrumb.Item>
                </Breadcrumb>
            </Header>

            {/* Table */}
            <div id="table-container">
                <div className="table-header-options">
                    <SelectPicker
                        style={{ marginRight: 15 }}
                        placeholder="Status seç"
                        data={ORDER_STATUSES}
                        onChange={(value) => setFilter({ ...filter, status: value })}
                    />
                    {/* <Button appearance="primary" onClick={() => router.push("orders/create")}>
                        Əlavə et
                    </Button> */}
                </div>
                <Table height={400} rowHeight={60} data={orders} loading={loading}>
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column width={200}>
                        <Table.HeaderCell>Sifarişçi</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.user.name}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={200}>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.status_name}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={150}>
                        <Table.HeaderCell>Ödəniş metodu</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.payment_method_name}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={300}>
                        <Table.HeaderCell>Ödəniş statusu</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.payment_status_name}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={100}>
                        <Table.HeaderCell>İzləmə kodu</Table.HeaderCell>
                        <Table.Cell dataKey="tracking_code"></Table.Cell>
                    </Table.Column>
                    <Table.Column width={100}>
                        <Table.HeaderCell>Cəmi</Table.HeaderCell>
                        <Table.Cell>{(rowData) => `₼${rowData.sub_total_price.toFixed(2)}`}</Table.Cell>
                    </Table.Column>
                    <Table.Column width={150}>
                        <Table.HeaderCell>Tarix</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => dateFormat(new Date(rowData.created_at), "dd-MM-yyyy hh:mm:ss")}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column width={150} fixed="right">
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "pager"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>

            {/* Modal Accept Order */}
            <Modal open={acceptModalOpen} onClose={() => setAcceptModalOpen(false)} overflow={true}>
                <Modal.Header>
                    <Modal.Title>Sifarişi təsdiqlə</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Checkbox
                        disabled={
                            acceptModalData
                                ? acceptModalData.products.filter((item) => item.is_stock_available).length === 0
                                : true
                        }
                        indeterminate={
                            acceptModalData
                                ? acceptModalCheckboxChecked.length > 0 &&
                                  acceptModalCheckboxChecked.length <
                                      acceptModalData.products.filter((item) => item.is_stock_available).length
                                : false
                        }
                        checked={
                            acceptModalData
                                ? acceptModalCheckboxChecked.length ===
                                  acceptModalData.products.filter((item) => item.is_stock_available).length
                                : false
                        }
                        onChange={(value, checked) =>
                            setAcceptModalCheckboxChecked(
                                checked
                                    ? acceptModalData.products
                                          .filter((item) => item.is_stock_available)
                                          .map((item) => ({
                                              id: item.id,
                                              stock_id: null,
                                          }))
                                    : []
                            )
                        }
                    >
                        Hamısını seç
                    </Checkbox>
                    {acceptModalData && !acceptModalLoading ? (
                        <List>
                            {acceptModalData.products.map((item, index) => (
                                <List.Item key={index}>
                                    <FlexboxGrid style={{ alignItems: "center" }}>
                                        <FlexboxGrid.Item
                                            colspan={2}
                                            style={{
                                                display: "flex",
                                                marginRight: 10,
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Checkbox
                                                key={item.id}
                                                value={item.id}
                                                disabled={!item.is_stock_available}
                                                onChange={(value) => onChangeCheckboxAcceptModal(value)}
                                                checked={acceptModalCheckboxChecked
                                                    .map((item) => item.id)
                                                    .includes(item.id)}
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item
                                            colspan={3}
                                            style={{
                                                display: "flex",
                                                marginRight: 10,
                                            }}
                                        >
                                            <img src={item.first_image_thumbnail} width="70" alt="product" />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <strong>{item.name}</strong>
                                            <span>{item.stock_bar_code}</span>
                                            <span>
                                                {(item.discount_price > 0 ? item.discount_price : item.price).toFixed(
                                                    2
                                                )}
                                                ₼ x {item.qty} ={" "}
                                                {(
                                                    (item.discount_price > 0 ? item.discount_price : item.price) *
                                                    item.qty
                                                ).toFixed(2)}
                                                ₼
                                            </span>
                                            <span
                                                style={{
                                                    color: item.is_stock_available ? "green" : "red",
                                                }}
                                            >
                                                {item.is_stock_available ? "Stokda var" : "Stokda yoxdur"}
                                                <br />
                                                <SelectPicker
                                                    required
                                                    size="sm"
                                                    placeholder="Stokdan çıxılacaq mağazanı seç"
                                                    disabledItemValues={item.stocks
                                                        .filter((stockItem) => item.qty > stockItem.stock)
                                                        .map((stockItem) => stockItem.id)}
                                                    data={item.stocks.map((stockItem) => ({
                                                        label: `${stockItem.store_name} (${stockItem.stock})`,
                                                        value: stockItem.id,
                                                    }))}
                                                    disabled={
                                                        !acceptModalCheckboxChecked.find(
                                                            (findItem) => findItem.id === item.id
                                                        )
                                                    }
                                                    value={
                                                        acceptModalCheckboxChecked.find(
                                                            (findItem) => findItem.id === item.id
                                                        )
                                                            ? acceptModalCheckboxChecked.find(
                                                                  (findItem) => findItem.id === item.id
                                                              ).stock_id
                                                            : null
                                                    }
                                                    onChange={(value) => onChangeStockAcceptModal(value, item.id)}
                                                />
                                                <br />
                                                {item.is_deactive &&
                                                    PRODUCT_DEACTIVE_REASONS.find(
                                                        (fItem) => fItem.value === item.reason_deactive
                                                    ).label}
                                            </span>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            ))}
                        </List>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            <Loader size="md" />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => acceptOrder(acceptModalData.id)} appearance="primary">
                        Təsdiqlə
                    </Button>
                    <Button
                        onClick={() => {
                            setAcceptModalOpen(false);
                        }}
                        appearance="subtle"
                    >
                        Bağla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
