export const SUPPORTED_LANGUAGES = ["az", "ru"];
export const BANNER_TYPES = [
    {
        label: "Slayder sol",
        value: 0,
        sizes: {
            width: 280,
            height: 455,
        },
    },
    {
        label: "Slayder",
        value: 1,
        sizes: {
            width: 880,
            height: 455,
        },
    },
    {
        label: "Ana səhifə",
        value: 2,
        sizes: {
            width: 0,
            height: 0,
        },
        children: [
            {
                label: "Banner 1",
                value: 0,
                sizes: {
                    width: 280,
                    height: 300,
                },
            },
            {
                label: "Banner 2",
                value: 1,
                sizes: {
                    width: 580,
                    height: 370,
                },
            },
            {
                label: "Banner 3",
                value: 2,
                sizes: {
                    width: 280,
                    height: 240,
                },
            },
            {
                label: "Banner 4",
                value: 3,
                sizes: {
                    width: 280,
                    height: 460,
                },
            },
            {
                label: "Banner 5",
                value: 4,
                sizes: {
                    width: 280,
                    height: 320,
                },
            },
            {
                label: "Banner 6",
                value: 5,
                sizes: {
                    width: 580,
                    height: 215,
                },
            },
            {
                label: "Banner 7",
                value: 6,
                sizes: {
                    width: 580,
                    height: 270,
                },
            },
            {
                label: "Banner 8",
                value: 7,
                sizes: {
                    width: 280,
                    height: 245,
                },
            },
            {
                label: "Banner 9",
                value: 8,
                sizes: {
                    width: 280,
                    height: 180,
                },
            },
        ],
    },
    {
        label: "Mega menu",
        value: 3,
        sizes: {
            width: 280,
            height: 180,
        },
    },
    {
        label: "Ana səhifə sol sidebar",
        value: 4,
        sizes: {
            width: 280,
            height: 440,
        },
    },
];
export const PRODUCT_DEACTIVE_REASONS = [
    {
        label: "Stok göstərir amma yoxdur",
        value: 0,
    },
    {
        label: "Məhsul problemlidir",
        value: 1,
    },
    {
        label: "Məhsulun şəkili yoxdur",
        value: 2,
    },
];
