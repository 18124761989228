import Categories from "pages/categories";
import CategoryCreate from "pages/categories/create";
import CategoryEdit from "pages/categories/edit";

const CategoryRoutes = [
    {
        path: "/categories",
        component: Categories,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/categories/:id/edit",
        component: CategoryEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/categories/create",
        component: CategoryCreate,
        private: true,
        privateRoles: [0],
    },
];

export default CategoryRoutes;
