import Brands from "pages/brands";
import BrandCreate from "pages/brands/create";
import BrandEdit from "pages/brands/edit";

const BrandRoutes = [
    {
        path: "/brands",
        component: Brands,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/brands/:id/edit",
        component: BrandEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: "/brands/create",
        component: BrandCreate,
        private: true,
        privateRoles: [0],
    },
];

export default BrandRoutes;
