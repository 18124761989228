import { useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema, Uploader } from "rsuite";
import { Image as IconImage } from "@rsuite/icons";
import Compressor from "compressorjs";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import slug from "slug";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    getFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";

export default function BlogCreate() {
    const initialValue = {
        image: "",
        title: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        content: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        url: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
    };
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [blog, setBlog] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["title", "content", "url"];

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        image: Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
        title: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        content: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Initialize a markdown parser
     */
    const mdParser = new MarkdownIt();
    const onEditorImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.8,
                async success(result) {
                    const formData = new FormData();
                    formData.append("file", result, result.name);
                    const uploaded = await axios.post("/blogs/image/editor/upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    resolve(`${process.env.REACT_APP_SITE_URL}/storage/blogs/editor/${uploaded.data.data}`);
                },
                error(err) {
                    showToaster("danger", err.message);
                    reject(false);
                },
            });
        });
    };

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/blogs`, blog);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setBlog(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     */
    const onRemoveImage = async (file) => {
        try {
            await axios.post("/blogs/image/remove", {
                file: file,
            });
            updateValueByKey(blog, setBlog, "image", "");
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/blogs">Blog</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={blog}
                    model={formModel}
                >
                    <div className="rs-form-control-wrapper">
                        <Form.Group>
                            <Form.ControlLabel>Şəkil</Form.ControlLabel>
                            <Uploader
                                action={`${process.env.REACT_APP_API_URL}/blogs/image/upload`}
                                headers={{
                                    Authorization: localStorage.getItem("access_token")
                                        ? `Bearer ${localStorage.getItem("access_token")}`
                                        : "",
                                }}
                                accept="image/*"
                                listType="picture"
                                fileListVisible={false}
                                onRemove={(file) => onRemoveImage(file.name)}
                                onUpload={(file) => {
                                    setUploadLoading(true);
                                    if (blog.image) {
                                        onRemoveImage(blog.image);
                                    }
                                }}
                                onSuccess={(response, file) => {
                                    file.name = response.data;
                                    updateValueByKey(blog, setBlog, "image", response.data);
                                    setUploadLoading(false);
                                }}
                                onError={() => {
                                    updateValueByKey(blog, setBlog, "image", "");
                                    setUploadLoading(false);
                                }}
                                shouldUpload={(file) => {
                                    if (file.status !== "inited") {
                                        return false;
                                    }
                                    return new Promise((resolve, reject) => {
                                        new Compressor(file.blobFile, {
                                            quality: 0.8,
                                            success(result) {
                                                file.blobFile = result;
                                                resolve(true);
                                            },
                                            error(err) {
                                                showToaster("danger", err.message);
                                                reject(false);
                                            },
                                        });
                                    });
                                }}
                                draggable
                            >
                                <button type="button" style={{ width: 150, height: 150 }}>
                                    {uploadLoading && <Loader backdrop center />}
                                    {blog.image ? (
                                        <img
                                            src={`${process.env.REACT_APP_SITE_URL}/storage/blogs/${blog.image}`}
                                            width="100%"
                                            alt="blog"
                                        />
                                    ) : (
                                        <IconImage style={{ fontSize: "5em" }} />
                                    )}
                                </button>
                            </Uploader>
                            <Form.ErrorMessage
                                show={getFormErrMessage(formErrors, "image") ? true : false}
                                placement="bottomStart"
                            >
                                {getFormErrMessage(formErrors, "image")}
                            </Form.ErrorMessage>
                        </Form.Group>
                    </div>
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{ display: selectedLang === lang ? "block" : "none", marginBottom: 24 }}
                        >
                            <InputField
                                type="text"
                                name={`title-${lang}`}
                                label="Başlıq"
                                value={blog.title[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "title", lang)}
                                onChange={(value) =>
                                    updateValueByKeys(blog, setBlog, {
                                        title: {
                                            ...blog.title,
                                            [lang]: value,
                                        },
                                        url: {
                                            ...blog.url,
                                            [lang]: slug(value),
                                        },
                                    })
                                }
                            />
                            <div className="rs-form-control-wrapper">
                                <MdEditor
                                    style={{ height: 500, marginBottom: 24 }}
                                    name={`content-${lang}`}
                                    value={blog.content[lang]}
                                    renderHTML={(text) => mdParser.render(text)}
                                    onImageUpload={onEditorImageUpload}
                                    onChange={(data, e) =>
                                        updateLangValueByKey(blog, setBlog, "content", lang, data.text)
                                    }
                                />
                                <Form.ErrorMessage
                                    show={getLangFormErrMessage(formErrors, "content", lang) ? true : false}
                                    placement="bottomStart"
                                >
                                    {getLangFormErrMessage(formErrors, "content", lang)}
                                </Form.ErrorMessage>
                            </div>
                            <InputField
                                type="text"
                                name={`url-${lang}`}
                                label="URL"
                                value={blog.url[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                onChange={(value) => updateLangValueByKey(blog, setBlog, "url", lang, value)}
                            />
                        </div>
                    ))}
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
