import { useState, useEffect } from "react";
import { Container, Content, FlexboxGrid, Panel, Form, ButtonToolbar, Button, Message } from "rsuite";
import { useAuth } from "contexts/authContext";
import { useRouter } from "utils/hooks";

export default function Login() {
    const auth = useAuth();
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMessage, setErrMessage] = useState("");

    useEffect(() => {
        if (auth.user) {
            router.push('/');
        }
    }, [auth.user, router]);

    /**
     * Sing In
     */
    const singIn = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        auth.signin(email, password, (response) => {
            setLoading(false);
            if (!response.success) {
                setErrMessage(response.message);
            }
        });
    };

    /**
     * Render
     */
    return auth.reqInfo && !auth.user ? (
        <div className="main login-page">
            <Container>
                <Content>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={12}>
                            <Panel header={<h3>Admin Panel</h3>} bordered>
                                {errMessage && (
                                    <Message showIcon type="error" header="Xəta" style={{ marginBottom: '1rem' }}>{errMessage}</Message>
                                )}
                                <Form fluid autoComplete="off">
                                    <Form.Group>
                                        <Form.ControlLabel>Email</Form.ControlLabel>
                                        <Form.Control name="name" onChange={(value) => setEmail(value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>Şifrə</Form.ControlLabel>
                                        <Form.Control name="password" type="password" onChange={(value) => setPassword(value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button appearance="primary" loading={loading} onClick={singIn}>Daxil ol</Button>
                                        </ButtonToolbar>
                                    </Form.Group>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
        </div>
    ) : null;
};