import { useState } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Loader,
    Form,
    Schema,
    DatePicker,
} from "rsuite";
import XLSX from "xlsx";
import { InputField, SubmitButton } from "components/form";
import axios from "utils/axios";
import { showToaster, updateValueByKey } from "utils/utils";

export default function PromotionCreate() {
    const initialValue = {
        name: "",
        bg_color: "",
        text_color: "",
        start_date: new Date(),
        end_date: new Date(),
        products: [],
    };
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(null);
    const [promotion, setPromotion] = useState(initialValue);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.StringType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
        bg_color: Schema.Types.StringType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
        text_color: Schema.Types.StringType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
        start_date: Schema.Types.DateType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
        end_date: Schema.Types.DateType().isRequired(
            "Bu xananın doldurulması məcburidir."
        ),
    });

    /**
     * Change Input Excel File
     * @param {*} value
     * @param {*} e
     * @returns
     */
    const onChangeExcelFile = async (value, e) => {
        if (e.target.files.length === 0) {
            return;
        }

        /**
         * Set Loading
         */
        setLoadingText("Excel faylı yüklənir...");
        setLoading(true);

        /**
         * Get Rows
         */
        const file = e.target.files[0];
        const fileData = await file.arrayBuffer();
        const workbook = XLSX.read(fileData);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils
            .sheet_to_json(worksheet, {
                raw: true,
                header: 1,
            })
            .slice(1)
            .filter((item) => item[0]);

        /**
         * Generate Products
         */
        const products = rows.map((item, ind) => {
            return {
                code: item[0],
                price: parseFloat(item[1]) || 0,
            };
        });
        setPromotion({
            ...promotion,
            products: products,
        });
        setLoading(false);
    };

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/promotions`, promotion);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setPromotion(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/promotions">
                        Promolar
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    formValue={promotion}
                    model={formModel}
                >
                    <InputField
                        type="text"
                        name="name"
                        label="Adı"
                        onChange={(value) =>
                            updateValueByKey(
                                promotion,
                                setPromotion,
                                "name",
                                value
                            )
                        }
                    />
                    <InputField
                        style={{ height: 36 }}
                        type="color"
                        name="bg_color"
                        label="Arxa fon rəngi"
                        onChange={(value) =>
                            updateValueByKey(
                                promotion,
                                setPromotion,
                                "bg_color",
                                value
                            )
                        }
                    />
                    <InputField
                        style={{ height: 36 }}
                        type="color"
                        name="text_color"
                        label="Yazı rəngi"
                        onChange={(value) =>
                            updateValueByKey(
                                promotion,
                                setPromotion,
                                "text_color",
                                value
                            )
                        }
                    />
                    <InputField
                        name="start_date"
                        label="Başlama tarixi"
                        accepter={DatePicker}
                        format="yyyy-MM-dd HH:mm:ss"
                        onChange={(value) =>
                            updateValueByKey(
                                promotion,
                                setPromotion,
                                "start_date",
                                value
                            )
                        }
                    />
                    <InputField
                        name="end_date"
                        label="Bitmə tarixi"
                        accepter={DatePicker}
                        format="yyyy-MM-dd HH:mm:ss"
                        onChange={(value) =>
                            updateValueByKey(
                                promotion,
                                setPromotion,
                                "end_date",
                                value
                            )
                        }
                    />
                    <InputField
                        type="file"
                        name="document"
                        label="Excel faylı"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onChange={onChangeExcelFile}
                    />
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && (
                    <Loader
                        style={{ zIndex: 99 }}
                        size="md"
                        backdrop
                        center
                        content={loadingText || "Yadda saxlanılır..."}
                    />
                )}
            </Content>
        </>
    );
}
