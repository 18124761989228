import { useState, useEffect } from "react";
import { Navbar, Nav, Dropdown, Container, Sidebar, Sidenav } from "rsuite";
import { ArrowLeft, ArrowRight } from "@rsuite/icons";
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useWindowSize } from "utils/hooks";
import { ProvideAuth, useAuth } from "contexts/authContext";
import NotFound from "pages/notFound";
import routes from "routes";
import navigations from "navigations";
import { checkRole } from "utils/utils";

/**
 * NavToggle Component
 */
const NavToggle = ({ expand, onChange, logout }) => {
    return (
        <Navbar className="nav-toggle">
            <Nav>
                <Dropdown placement="topStart" trigger="click">
                    {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                    <Dropdown.Item onClick={() => logout()}>Çıxış et</Dropdown.Item>
                </Dropdown>
            </Nav>
            <Nav pullRight>
                <Nav.Item onClick={onChange} style={{ width: 56, textAlign: "center" }}>
                    {expand ? <ArrowLeft /> : <ArrowRight />}
                </Nav.Item>
            </Nav>
        </Navbar>
    );
};

/**
 * NavItem Component
 */
const NavItem = ({ label, icon, to, index }) => {
    let match = useRouteMatch({
        path: to,
        exact: true,
    });
    return (
        <Nav.Item eventKey={index} active={match ? true : false} icon={icon} href={to}>
            {label}
        </Nav.Item>
    );
};

/**
 * PrivateRoute
 */
function PrivateRoute({ children, comp: Component, privateRoles, ...rest }) {
    const auth = useAuth();
    const [expand, setExpand] = useState(true);
    const { width } = useWindowSize();
    useEffect(() => {
        setExpand(width > 768);
    }, [width]);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.reqInfo && auth.user && checkRole(privateRoles, auth.user.role) ? (
                    <div className="main sidebar-page">
                        <Container>
                            <Sidebar
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                width={expand ? 260 : 56}
                                collapsible
                            >
                                <Sidenav.Header>
                                    <div
                                        style={{
                                            padding: 18,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            height: 56,
                                            background: "#34c3ff",
                                            color: "#fff",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textAlign: "center",
                                        }}
                                    >
                                        <span>CARPETCITY ADMIN PANEL</span>
                                    </div>
                                </Sidenav.Header>
                                <Sidenav expanded={expand}>
                                    <Sidenav.Body>
                                        <Nav>
                                            {navigations.map((parentItem, parentInd) =>
                                                parentItem.dropdowns && checkRole(parentItem.roles, auth.user.role) ? (
                                                    <Dropdown
                                                        key={parentInd}
                                                        eventKey={parentInd}
                                                        trigger="hover"
                                                        title={parentItem.label}
                                                        icon={parentItem.icon}
                                                        placement="rightStart"
                                                    >
                                                        {parentItem.dropdowns.map(
                                                            (dropdownItem, dropdownInd) =>
                                                                checkRole(dropdownItem.roles, auth.user.role) && (
                                                                    <Dropdown.Item
                                                                        key={dropdownInd}
                                                                        eventKey={`${parentInd}-${dropdownInd}`}
                                                                        href={dropdownItem.to}
                                                                    >
                                                                        {dropdownItem.label}
                                                                    </Dropdown.Item>
                                                                )
                                                        )}
                                                    </Dropdown>
                                                ) : (
                                                    checkRole(parentItem.roles, auth.user.role) && (
                                                        <NavItem
                                                            key={parentInd}
                                                            index={parentInd}
                                                            label={parentItem.label}
                                                            icon={parentItem.icon}
                                                            to={parentItem.to}
                                                        />
                                                    )
                                                )
                                            )}
                                        </Nav>
                                    </Sidenav.Body>
                                </Sidenav>
                                <NavToggle expand={expand} onChange={() => setExpand(!expand)} logout={auth.signout} />
                            </Sidebar>
                            <Container>
                                <Component />
                            </Container>
                        </Container>
                    </div>
                ) : auth.reqInfo && (!auth.user || !checkRole(privateRoles, auth.user.role)) ? (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {
                                from: location,
                            },
                        }}
                    />
                ) : null
            }
        />
    );
}

/**
 * Application
 */
export default function App() {
    return (
        <ProvideAuth>
            <Router>
                <Container>
                    <Switch>
                        {/* Routes */}
                        {routes.map((route, i) => {
                            return route.private ? (
                                <PrivateRoute
                                    key={i}
                                    exact
                                    path={route.path}
                                    privateRoles={route.privateRoles || []}
                                    comp={route.component}
                                />
                            ) : (
                                <Route key={i} exact path={route.path} component={route.component} />
                            );
                        })}

                        {/* 404 Not Found */}
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </Container>
            </Router>
        </ProvideAuth>
    );
}
