import { useCallback, useEffect, useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema, Grid, Row, Col, IconButton } from "rsuite";
import { Trash, Plus } from "@rsuite/icons";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
} from "utils/utils";

export default function CityEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(null);
    const [selectedLang, setSelectedLang] = useState("az");
    const [city, setCity] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name"];

    /**
     * Fetch City
     */
    const fetchCity = useCallback(async () => {
        try {
            const response = await axios.get(`/shipping-prices/cities/${id}`);
            setCity(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setCity(undefined);
        }
    }, [id]);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchCity();
    }, [fetchCity]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/shipping-prices/cities/${id}`, city);
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
                fetchCity();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/shipping-prices/cities">Çatdırılma qiymətləri (Şəhərlər)</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                {city === undefined && <NotFound />}
                {city && (
                    <>
                        <Nav
                            appearance="tabs"
                            activeKey={selectedLang}
                            onSelect={(key) => setSelectedLang(key)}
                            style={{ marginBottom: 20 }}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <Nav.Item key={i} eventKey={lang}>
                                    {lang.toUpperCase()}
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Form
                            fluid
                            onSubmit={onSubmit}
                            onError={(errors) =>
                                onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                            }
                            formValue={city}
                            model={formModel}
                        >
                            {SUPPORTED_LANGUAGES.map((lang, i) => (
                                <div
                                    key={i}
                                    id={`tabs-${lang}`}
                                    style={{
                                        display: selectedLang === lang ? "block" : "none",
                                        marginBottom: 24,
                                    }}
                                >
                                    <InputField
                                        type="text"
                                        name={`name-${lang}`}
                                        value={city.name[lang]}
                                        label="Adı"
                                        errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                        onChange={(value) => updateLangValueByKey(city, setCity, "name", lang, value)}
                                    />
                                </div>
                            ))}
                            <div id="prices" style={{ marginBottom: 24 }}>
                                <h5>Çatdırılma qiymətləri</h5>
                                <Grid fluid style={{ padding: 0, marginTop: 24 }}>
                                    {city.prices.length > 0 ? (
                                        city.prices.map((value, i) => (
                                            <Row key={i} style={{ marginBottom: 24 }}>
                                                <Col xs={7}>
                                                    <InputField
                                                        type="number"
                                                        step="any"
                                                        name={`order_min_price-${i}`}
                                                        label="Minimum sifariş qiyməti"
                                                        value={city.prices[i].order_min_price}
                                                        onChange={(value) => {
                                                            city.prices[i].order_min_price = parseFloat(value) || 0;
                                                            updateValueByKey(city, setCity, "prices", city.prices);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={7}>
                                                    <InputField
                                                        type="number"
                                                        step="any"
                                                        name={`order_max_price-${i}`}
                                                        label="Maksimum sifariş qiyməti"
                                                        value={city.prices[i].order_max_price}
                                                        onChange={(value) => {
                                                            city.prices[i].order_max_price = parseFloat(value) || 0;
                                                            updateValueByKey(city, setCity, "prices", city.prices);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={7}>
                                                    <InputField
                                                        type="number"
                                                        step="any"
                                                        name={`price-${i}`}
                                                        label="Çatdırılma qiyməti"
                                                        value={city.prices[i].price}
                                                        onChange={(value) => {
                                                            city.prices[i].price = parseFloat(value) || 0;
                                                            updateValueByKey(city, setCity, "prices", city.prices);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={3} style={{ marginTop: 24 }}>
                                                    <IconButton
                                                        icon={<Trash />}
                                                        onClick={() =>
                                                            updateValueByKey(
                                                                city,
                                                                setCity,
                                                                "prices",
                                                                city.prices.filter(
                                                                    (item) => item.id !== value.id || item.i !== value.i
                                                                )
                                                            )
                                                        }
                                                    />
                                                    {city.prices.length === i + 1 && (
                                                        <IconButton
                                                            icon={<Plus />}
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                            onClick={() => {
                                                                city.prices.push({
                                                                    i:
                                                                        Math.max(
                                                                            ...city.prices
                                                                                .map((maxItem) => maxItem.i)
                                                                                .filter((maxItem) => maxItem),
                                                                            0
                                                                        ) + 1,
                                                                    order_min_price: 0,
                                                                    order_max_price: 0,
                                                                    price: 0,
                                                                });
                                                                updateValueByKey(city, setCity, "prices", city.prices);
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <IconButton
                                            icon={<Plus />}
                                            onClick={() => {
                                                city.prices.push({
                                                    i: 0,
                                                    order_min_price: 0,
                                                    order_max_price: 0,
                                                    price: 0,
                                                });
                                                updateValueByKey(city, setCity, "prices", city.prices);
                                            }}
                                        />
                                    )}
                                </Grid>
                            </div>
                            <SubmitButton text="Yadda saxla" />
                        </Form>
                    </>
                )}
                {loading && <Loader size="md" backdrop center content={loadingText || "Zəhmət olmasa gözləyin..."} />}
            </Content>
        </>
    );
}
