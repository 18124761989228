import Home from "pages/home";
import Login from "pages/login";

const DefaultRoutes = [
    {
        path: "/",
        component: Home,
        private: true,
        privateRoles: [],
    },
    {
        path: "/login",
        component: Login,
        private: false,
    },
];

export default DefaultRoutes;
