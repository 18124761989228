import Products from "pages/products";
import ProductCreate from "pages/products/create";
import ProductEdit from "pages/products/edit";

const ProductRoutes = [
    {
        path: "/products",
        component: Products,
        private: true,
        privateRoles: [0, 1],
    },
    {
        path: "/products/:id/edit",
        component: ProductEdit,
        private: true,
        privateRoles: [0, 1],
    },
    {
        path: "/products/create",
        component: ProductCreate,
        private: true,
        privateRoles: [0, 1],
    },
];

export default ProductRoutes;
