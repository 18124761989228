import Blogs from "pages/blogs";
import BlogCreate from "pages/blogs/create";
import BlogEdit from "pages/blogs/edit";

const BlogRoutes = [
    {
        path: '/blogs',
        component: Blogs,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/blogs/:id/edit',
        component: BlogEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/blogs/create',
        component: BlogCreate,
        private: true,
        privateRoles: [0],
    },
];

export default BlogRoutes;