import { Content } from "rsuite";
import { WarningRound } from "@rsuite/icons";

export default function NotFound() {
    return (
        <Content style={{ color: 'red', margin: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <WarningRound style={{ fontSize: '4em', marginBottom: '1rem' }}/>
            <strong style={{ fontSize: '2em' }}>404 NOT FOUND</strong>
        </Content>
    );
};