import Cities from "pages/shipping-prices/cities";
import CityCreate from "pages/shipping-prices/cities/create";
import CityEdit from "pages/shipping-prices/cities/edit";

const CityRoutes = [
    {
        path: '/shipping-prices/cities',
        component: Cities,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/shipping-prices/cities/:id/edit',
        component: CityEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/shipping-prices/cities/create',
        component: CityCreate,
        private: true,
        privateRoles: [0],
    },
];

export default CityRoutes;