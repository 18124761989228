import { useCallback, useEffect, useState } from "react";
import { Header, Content, IconButton, Breadcrumb, Loader, Row, Col, Table } from "rsuite";
import { Visible } from "@rsuite/icons";
import { useParams } from "react-router-dom";
import NotFound from "components/notFound";
import axios from "utils/axios";
import { dateFormat } from "utils/utils";

export default function OrderView() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);

    /**
     * Fetch Order
     */
    const fetchOrder = useCallback(async () => {
        try {
            const response = await axios.get(`/orders/${id}`);
            setOrder(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setOrder(undefined);
        }
    }, [id]);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchOrder();
    }, [fetchOrder]);

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() =>
                        window.open(`${process.env.REACT_APP_SITE_URL}/p/${rowData.id}/${rowData.url}`, "_blank")
                    }
                    icon={<Visible />}
                />
            </Table.Cell>
        );
    };

    /**
     * Table Product Name Cell Component
     */
    const TableProductNameCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props}>
                <div>
                    <div style={{ display: "block", fontSize: 14 }}>{rowData.name}</div>
                    {rowData.selected_variants.length > 0 &&
                        rowData.selected_variants.map((variant, i) => (
                            <div key={i} style={{ display: "block", fontSize: 12 }}>
                                <strong>{variant.name}:</strong> {variant.value.name}
                            </div>
                        ))}
                </div>
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/orders">Sifarişlər</Breadcrumb.Item>
                    <Breadcrumb.Item active>Bax</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            {(loading || order === undefined) && (
                <Content>
                    {order === undefined && <NotFound />}
                    {loading && <Loader size="md" backdrop center content="Zəhmət olmasa gözləyin..." />}
                </Content>
            )}
            {order && (
                <div id="order">
                    <Content>
                        <h5>Sifariş məlumatları</h5>
                        <hr />
                        <Row className="information">
                            <Col xs={8}>
                                <strong>Sifarişçi:</strong>
                                <span>{order.user.name}</span>
                            </Col>
                            {order.user.phone && (
                                <Col xs={8}>
                                    <strong>Telefon nömrəsi:</strong>
                                    <span>+994{order.user.phone}</span>
                                </Col>
                            )}
                            <Col xs={8}>
                                <strong>Status:</strong>
                                <span>{order.status_name}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Ödəniş metodu:</strong>
                                <span>{order.payment_method_name}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Ödəniş statusu:</strong>
                                <span>{order.payment_status_name}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Çatdırılma ünvanı:</strong>
                                <span>{order.shipping_address}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>İzləmə kodu:</strong>
                                <span>{order.tracking_code}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Sifariş qiyməti:</strong>
                                <span>₼{order.total_price.toFixed(2)}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Çatdırılma qiyməti:</strong>
                                <span>₼{order.shipping_price.toFixed(2)}</span>
                            </Col>
                            <Col xs={8}>
                                <strong>Cəmi qiymət:</strong>
                                <span>₼{order.sub_total_price.toFixed(2)}</span>
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <h5>İzləmə məlumatları</h5>
                        <hr />
                        <ul className="tracking-lists">
                            {order.statuses.map((item) => (
                                <li>
                                    <strong>{dateFormat(new Date(item.created_at), "dd-MM-yyyy hh:mm:ss")}:</strong>{" "}
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </Content>
                    <Content>
                        <h5>Məhsullar</h5>
                        <hr />
                        <Table height={400} rowHeight={70} data={order.products}>
                            <Table.Column width={70} fixed>
                                <Table.HeaderCell>Şəkil</Table.HeaderCell>
                                <Table.Cell style={{ padding: 0 }}>
                                    {(rowData) => <img src={rowData.first_image_thumbnail} width="70" alt="product" />}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Məhsul</Table.HeaderCell>
                                <TableProductNameCell />
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Barkod</Table.HeaderCell>
                                <Table.Cell>{(rowData) => rowData.stock_bar_code}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Qiymət</Table.HeaderCell>
                                <Table.Cell>
                                    {(rowData) =>
                                        "₼" +
                                        (rowData.discount_price > 0 ? rowData.discount_price : rowData.price).toFixed(2)
                                    }
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Sayı</Table.HeaderCell>
                                <Table.Cell>{(rowData) => rowData.qty}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Cəmi qiymət</Table.HeaderCell>
                                <Table.Cell>
                                    {(rowData) =>
                                        "₼" +
                                        (
                                            (rowData.discount_price > 0 ? rowData.discount_price : rowData.price) *
                                            rowData.qty
                                        ).toFixed(2)
                                    }
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Təsdiqləndi?</Table.HeaderCell>
                                <Table.Cell>{(rowData) => (rowData.accepted === 1 ? "Bəli" : "Xeyr")}</Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={1} fixed>
                                <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                                <ActionCell />
                            </Table.Column>
                        </Table>
                    </Content>
                </div>
            )}
        </>
    );
}
