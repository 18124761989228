import { useCallback, useEffect, useState } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Nav,
    Loader,
    Form,
    Schema,
    Toggle,
    SelectPicker,
    TagInput,
    Grid,
    Row,
    Col,
    IconButton,
    Uploader,
    Footer,
    Input,
} from "rsuite";
import { Trash, Plus, Image } from "@rsuite/icons";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import { SUPPORTED_LANGUAGES, PRODUCT_DEACTIVE_REASONS } from "appConstants";
import axios from "utils/axios";
import Compressor from "compressorjs";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";
import slug from "slug";

export default function ProductEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(null);
    const [selectedLang, setSelectedLang] = useState("az");
    const [parentCategories, setParentCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [stores, setStores] = useState([]);
    const [variants, setVariants] = useState([]);
    const [deletedVariants, setDeletedVariants] = useState([]);
    const [product, setProduct] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "url"];

    /**
     * Fetch Product
     */
    const fetchProduct = useCallback(async () => {
        try {
            const response = await axios.get(`/products/${id}`);
            setProduct(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setProduct(undefined);
        }
    }, [id]);

    /**
     * Fetch Categories
     */
    const fetchCategories = useCallback(async (setState, parent_id = null) => {
        try {
            const response = await axios.get(`/categories?parent_id=${parent_id}`);
            setState(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.id,
                }))
            );
        } catch (error) {}
    }, []);

    /**
     * Fetch Brands
     */
    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(`/brands`);
            setBrands(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.id,
                }))
            );
        } catch (error) {}
    }, []);

    /**
     * Fetch Stores
     */
    const fetchStores = useCallback(async () => {
        try {
            const response = await axios.get(`/stores`);
            setStores(response.data.data.lists);
        } catch (error) {}
    }, []);

    /**
     * Fetch Variants
     */
    const fetchVariants = useCallback(async () => {
        try {
            const response = await axios.get(`/variants`);
            setVariants(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.id,
                    valueItems: item.values.map((itemValue) => ({
                        label: itemValue.name.az,
                        value: itemValue.id,
                    })),
                }))
            );
        } catch (error) {}
    }, []);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchProduct();
        fetchCategories(setParentCategories);
        fetchBrands();
        fetchStores();
        fetchVariants();
    }, [fetchProduct, fetchCategories, fetchBrands, fetchStores, fetchVariants]);

    /**
     * Listening Product Data
     */
    useEffect(() => {
        if (product && product.parent_cat_id) {
            fetchCategories(setCategories, product.parent_cat_id);
        }
        if (product && product.cat_id) {
            fetchCategories(setSubCategories, product.cat_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        stock_code: Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
        stock_bar_code: Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
        parent_cat_id: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
        // cat_id: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
        // sub_cat_id: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
        price: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/products/${id}`, {
                ...product,
                deletedVariants,
            });
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
                fetchProduct();
                setDeletedVariants([]);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     */
    const onRemoveImage = async (file) => {
        try {
            await axios.post("/products/image/remove", {
                file: file,
                product_id: product.id,
            });
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * shouldUploadImage
     * @param {*} file
     * @returns
     */
    const shouldUploadImage = (file) => {
        if (file.status !== "inited") {
            return false;
        }
        return new Promise((resolve, reject) => {
            new Compressor(file.blobFile, {
                quality: 0.8,
                success(result) {
                    file.blobFile = result;
                    resolve(true);
                },
                error(err) {
                    showToaster("danger", err.message);
                    reject(false);
                },
            });
        });
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/products">Məhsullar</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            {(loading || product === undefined) && (
                <Content>
                    {product === undefined && <NotFound />}
                    {loading && <Loader size="md" backdrop center content={loadingText} />}
                </Content>
            )}

            {/* Product */}
            {product && (
                <div id="product">
                    <Form
                        fluid
                        onSubmit={onSubmit}
                        onError={(errors) =>
                            onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                        }
                        formValue={product}
                        model={formModel}
                        style={{ margin: 20 }}
                    >
                        <div id="product-data">
                            <Content style={{ margin: 0 }}>
                                <Nav
                                    appearance="tabs"
                                    activeKey={selectedLang}
                                    onSelect={(key) => setSelectedLang(key)}
                                    style={{ marginBottom: 20 }}
                                >
                                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                                        <Nav.Item key={i} eventKey={lang}>
                                            {lang.toUpperCase()}
                                        </Nav.Item>
                                    ))}
                                </Nav>

                                {SUPPORTED_LANGUAGES.map((lang, i) => (
                                    <div
                                        key={i}
                                        id={`tabs-${lang}`}
                                        style={{
                                            display: selectedLang === lang ? "block" : "none",
                                            marginBottom: 24,
                                        }}
                                    >
                                        <InputField
                                            type="text"
                                            name={`name-${lang}`}
                                            value={product.name[lang]}
                                            label="Adı"
                                            errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                            onChange={(value) =>
                                                updateValueByKeys(product, setProduct, {
                                                    name: {
                                                        ...product.name,
                                                        [lang]: value,
                                                    },
                                                    url: {
                                                        ...product.url,
                                                        [lang]: slug(value),
                                                    },
                                                })
                                            }
                                        />
                                        <InputField
                                            type="text"
                                            name={`url-${lang}`}
                                            value={product.url[lang]}
                                            label="URL"
                                            errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                            onChange={(value) =>
                                                updateLangValueByKey(product, setProduct, "url", lang, value)
                                            }
                                        />
                                        <hr />
                                        <h5>SEO</h5>
                                        <hr />
                                        <InputField
                                            textarea={true}
                                            rows={5}
                                            name={`seo_description-${lang}`}
                                            label="Description"
                                            value={
                                                product.seo_description && product.seo_description[lang]
                                                    ? product.seo_description[lang]
                                                    : ""
                                            }
                                            errorMessage={getLangFormErrMessage(formErrors, "seo_description", lang)}
                                            onChange={(value) =>
                                                updateLangValueByKey(
                                                    product,
                                                    setProduct,
                                                    "seo_description",
                                                    lang,
                                                    value
                                                )
                                            }
                                        />
                                        <InputField
                                            accepter={TagInput}
                                            name={`seo_keyword-${lang}`}
                                            label="Açar sözləri"
                                            value={
                                                product.seo_keywords && product.seo_keywords[lang]
                                                    ? product.seo_keywords[lang].split(", ")
                                                    : []
                                            }
                                            errorMessage={getLangFormErrMessage(formErrors, "seo_keywords", lang)}
                                            onChange={(value) =>
                                                updateLangValueByKey(
                                                    product,
                                                    setProduct,
                                                    "seo_keywords",
                                                    lang,
                                                    value.join(", ")
                                                )
                                            }
                                        />

                                        {/* Features */}
                                        <hr />
                                        <div id="features" style={{ marginBottom: 24 }}>
                                            <h5>Xüsusiyyətlər</h5>
                                            <Grid
                                                fluid
                                                style={{
                                                    padding: 0,
                                                    marginTop: 24,
                                                }}
                                            >
                                                {product.features &&
                                                product.features[lang] &&
                                                product.features[lang].length > 0 ? (
                                                    product.features[lang].map((value, i) => (
                                                        <Row
                                                            key={i}
                                                            style={{
                                                                marginBottom: 24,
                                                            }}
                                                        >
                                                            <Col xs={7}>
                                                                <InputField
                                                                    type="text"
                                                                    name={`key-${i}`}
                                                                    label="Başlıq"
                                                                    value={product.features[lang][i].key}
                                                                    onChange={(value) => {
                                                                        product.features[lang][i].key = value;
                                                                        updateValueByKey(
                                                                            product,
                                                                            setProduct,
                                                                            "features",
                                                                            product.features
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col xs={7}>
                                                                <InputField
                                                                    type="text"
                                                                    name={`value-${i}`}
                                                                    label="Dəyər"
                                                                    value={product.features[lang][i].value}
                                                                    onChange={(value) => {
                                                                        product.features[lang][i].value = value;
                                                                        updateValueByKey(
                                                                            product,
                                                                            setProduct,
                                                                            "features",
                                                                            product.features
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs={3}
                                                                style={{
                                                                    marginTop: 24,
                                                                }}
                                                            >
                                                                <IconButton
                                                                    icon={<Trash />}
                                                                    onClick={() => {
                                                                        const newFeatures = product.features[
                                                                            lang
                                                                        ].filter(
                                                                            (item) =>
                                                                                item.id !== value.id ||
                                                                                item.i !== value.i
                                                                        );
                                                                        product.features[lang] = newFeatures;
                                                                        updateValueByKey(
                                                                            product,
                                                                            setProduct,
                                                                            "features",
                                                                            product.features
                                                                        );
                                                                    }}
                                                                />
                                                                {product.features[lang].length === i + 1 && (
                                                                    <IconButton
                                                                        icon={<Plus />}
                                                                        style={{
                                                                            marginLeft: 10,
                                                                        }}
                                                                        onClick={() => {
                                                                            product.features[lang].push({
                                                                                i:
                                                                                    Math.max(
                                                                                        ...product.features[lang]
                                                                                            .map((maxItem) => maxItem.i)
                                                                                            .filter(
                                                                                                (maxItem) => maxItem
                                                                                            ),
                                                                                        0
                                                                                    ) + 1,
                                                                                key: "",
                                                                                value: "",
                                                                            });
                                                                            updateValueByKey(
                                                                                product,
                                                                                setProduct,
                                                                                "features",
                                                                                product.features
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : (
                                                    <IconButton
                                                        icon={<Plus />}
                                                        onClick={() => {
                                                            product.features[lang].push({
                                                                i: 0,
                                                                key: "",
                                                                value: "",
                                                            });
                                                            updateValueByKey(
                                                                product,
                                                                setProduct,
                                                                "features",
                                                                product.features
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </div>
                                        <hr />
                                    </div>
                                ))}

                                {/* Images */}
                                <div id="images">
                                    <h5>Şəkillər</h5>
                                    <hr />
                                    <Uploader
                                        multiple
                                        listType="picture"
                                        accept="image/*"
                                        action={`${process.env.REACT_APP_API_URL}/products/image/upload`}
                                        data={{
                                            product_id: product.id,
                                        }}
                                        headers={{
                                            Authorization: localStorage.getItem("access_token")
                                                ? `Bearer ${localStorage.getItem("access_token")}`
                                                : "",
                                        }}
                                        defaultFileList={product.images}
                                        onRemove={(file) => onRemoveImage(file.name)}
                                        onError={(reason, file) => {
                                            console.log(reason, file);
                                            showToaster("danger", "Xəta: " + file.name);
                                        }}
                                        onSuccess={(response, file) => {
                                            file.name = response.data.image;
                                        }}
                                        shouldUpload={shouldUploadImage}
                                    >
                                        <button type="button">
                                            <Image />
                                        </button>
                                    </Uploader>
                                    <hr />
                                </div>

                                {/* Others */}
                                <InputField
                                    type="text"
                                    name="stock_code"
                                    label="Stok kodu"
                                    onChange={(value) => updateValueByKey(product, setProduct, "stock_code", value)}
                                />
                                <InputField
                                    type="text"
                                    name="stock_bar_code"
                                    label="Stok bar kodu"
                                    onChange={(value) => updateValueByKey(product, setProduct, "stock_bar_code", value)}
                                />
                                <InputField
                                    accepter={SelectPicker}
                                    data={parentCategories}
                                    virtualized
                                    name="parent_cat_id"
                                    label="Ana kateqoriya"
                                    onChange={(value) => {
                                        updateValueByKey(product, setProduct, "parent_cat_id", value);
                                        fetchCategories(setCategories, value);
                                    }}
                                />
                                {categories.length > 0 && product.parent_cat_id && (
                                    <InputField
                                        accepter={SelectPicker}
                                        data={categories}
                                        virtualized
                                        name="cat_id"
                                        label="Ara kateqoriya"
                                        onChange={(value) => {
                                            updateValueByKey(product, setProduct, "cat_id", value);
                                            fetchCategories(setSubCategories, value);
                                        }}
                                    />
                                )}
                                {subCategories.length > 0 && product.cat_id && (
                                    <InputField
                                        accepter={SelectPicker}
                                        data={subCategories}
                                        virtualized
                                        name="sub_cat_id"
                                        label="Alt kateqoriya"
                                        onChange={(value) => updateValueByKey(product, setProduct, "sub_cat_id", value)}
                                    />
                                )}
                                {/* <InputField
                                            type="text"
                                            name="sector_id"
                                            label="Sektor"
                                            onChange={(value) => updateValueByKey(product, setProduct, "sector_id", value)}
                                        /> */}
                                <InputField
                                    accepter={SelectPicker}
                                    data={brands}
                                    virtualized
                                    name="brand_id"
                                    label="Marka"
                                    onChange={(value) => updateValueByKey(product, setProduct, "brand_id", value)}
                                />
                                <InputField
                                    type="number"
                                    step="any"
                                    name="price"
                                    label="Qiymət"
                                    onChange={(value) =>
                                        updateValueByKey(product, setProduct, "price", parseFloat(value) || 0)
                                    }
                                />
                                <InputField
                                    type="number"
                                    step="any"
                                    name="discount_price"
                                    label="Endirimli qiymət"
                                    onChange={(value) =>
                                        updateValueByKey(product, setProduct, "discount_price", parseFloat(value) || 0)
                                    }
                                />
                                <InputField
                                    accepter={Toggle}
                                    name="is_active"
                                    label="Status"
                                    defaultChecked={product.is_active === 1}
                                    onChange={(checked) =>
                                        updateValueByKey(product, setProduct, "is_active", checked ? 1 : 0)
                                    }
                                />
                                {product.is_active === 0 && (
                                    <InputField
                                        accepter={SelectPicker}
                                        name="reason_deactive"
                                        label="Deaktiv olunma səbəbi"
                                        data={PRODUCT_DEACTIVE_REASONS}
                                        onChange={(value) =>
                                            updateValueByKey(
                                                product,
                                                setProduct,
                                                "reason_deactive",
                                                parseInt(value) || null
                                            )
                                        }
                                    />
                                )}

                                {/* Stocks */}
                                {stores.length > 0 && (
                                    <>
                                        <hr />
                                        <h5>Stok məlumatları</h5>
                                        <hr />
                                        <ul
                                            className="product-stock-lists"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                listStyle: "none",
                                            }}
                                        >
                                            {stores.map((store, index) => {
                                                const stockIndex = product.stocks.findIndex(
                                                    (stockItem) =>
                                                        stockItem.store_id === store.id &&
                                                        stockItem.product_variant_id === null
                                                );
                                                return (
                                                    <li key={index}>
                                                        <strong>{store.name.az}:</strong>
                                                        <InputField
                                                            style={{ width: "auto" }}
                                                            type="number"
                                                            value={
                                                                stockIndex > -1 ? product.stocks[stockIndex].stock : 0
                                                            }
                                                            onChange={(value) => {
                                                                let stocks = product.stocks;
                                                                if (stockIndex === -1) {
                                                                    stocks.push({
                                                                        store_id: store.id,
                                                                        product_variant_id: null,
                                                                        stock: parseInt(value) || 0,
                                                                    });
                                                                } else {
                                                                    stocks[stockIndex].stock = parseInt(value) || 0;
                                                                }
                                                                updateValueByKey(product, setProduct, "stocks", stocks);
                                                            }}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <hr />
                                    </>
                                )}
                            </Content>
                        </div>

                        {/* Variations */}
                        <div id="product-variants" style={{ marginTop: 25, marginBottom: 25 }}>
                            <h3>Məhsul seçimləri</h3>
                            {(product.variants && product.variants.length) > 0 ? (
                                product.variants.map((variant, i) => (
                                    <div key={i} class="variant-container" style={{ marginBottom: 35, marginTop: 15 }}>
                                        {/* Content */}
                                        <Content style={{ margin: 0 }}>
                                            <Grid
                                                fluid
                                                style={{
                                                    padding: 0,
                                                    marginTop: 24,
                                                }}
                                            >
                                                <Row
                                                    style={{
                                                        marginBottom: 24,
                                                    }}
                                                >
                                                    <Col xs={7}>
                                                        <label>Stok kodu</label>
                                                        <Input
                                                            defaultValue={product.variants[i].stock_code}
                                                            placeholder="Stok kodu"
                                                            onChange={(value) => {
                                                                product.variants[i].stock_code = value;
                                                                updateValueByKey(
                                                                    product,
                                                                    setProduct,
                                                                    "variants",
                                                                    product.variants
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={7}>
                                                        <label>Qiymət</label>
                                                        <Input
                                                            defaultValue={product.variants[i].price}
                                                            placeholder="Qiymət"
                                                            type="number"
                                                            step="any"
                                                            onChange={(value) => {
                                                                product.variants[i].price = parseFloat(value);
                                                                updateValueByKey(
                                                                    product,
                                                                    setProduct,
                                                                    "variants",
                                                                    product.variants
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={7}>
                                                        <label>Endirimli qiymət</label>
                                                        <Input
                                                            defaultValue={product.variants[i].discount_price}
                                                            placeholder="Endirimli qiymət"
                                                            type="number"
                                                            step="any"
                                                            onChange={(value) => {
                                                                product.variants[i].discount_price = parseFloat(value);
                                                                updateValueByKey(
                                                                    product,
                                                                    setProduct,
                                                                    "variants",
                                                                    product.variants
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                {/* Variant Values */}
                                                {product.variants[i].values.length > 0 ? (
                                                    product.variants[i].values.map((valueItem, iValue) => (
                                                        <Row
                                                            key={iValue}
                                                            style={{
                                                                marginBottom: 24,
                                                            }}
                                                        >
                                                            <Col xs={7}>
                                                                <SelectPicker
                                                                    placeholder="Variant"
                                                                    block
                                                                    data={variants}
                                                                    value={
                                                                        product.variants[i].values[iValue].variant_id
                                                                    }
                                                                    onChange={(value) => {
                                                                        product.variants[i].values[iValue].variant_id =
                                                                            value;
                                                                        updateValueByKey(
                                                                            product,
                                                                            setProduct,
                                                                            "variants",
                                                                            product.variants
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            {product.variants[i].values[iValue].variant_id && (
                                                                <Col xs={7}>
                                                                    <SelectPicker
                                                                        placeholder="Variant dəyəri"
                                                                        block
                                                                        data={
                                                                            product.variants[i].values[iValue]
                                                                                .variant_id
                                                                                ? variants.find(
                                                                                      (item) =>
                                                                                          item.value ===
                                                                                          product.variants[i].values[
                                                                                              iValue
                                                                                          ].variant_id
                                                                                  )
                                                                                    ? variants.find(
                                                                                          (item) =>
                                                                                              item.value ===
                                                                                              product.variants[i]
                                                                                                  .values[iValue]
                                                                                                  .variant_id
                                                                                      ).valueItems
                                                                                    : []
                                                                                : []
                                                                        }
                                                                        value={
                                                                            product.variants[i].values[iValue].value_id
                                                                        }
                                                                        onChange={(value) => {
                                                                            product.variants[i].values[
                                                                                iValue
                                                                            ].value_id = value;
                                                                            updateValueByKey(
                                                                                product,
                                                                                setProduct,
                                                                                "variants",
                                                                                product.variants
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>
                                                            )}
                                                            <Col xs={7}>
                                                                <IconButton
                                                                    appearance="primary"
                                                                    color="red"
                                                                    icon={<Trash />}
                                                                    onClick={() => {
                                                                        const newVariantValues = product.variants[
                                                                            i
                                                                        ].values.filter(
                                                                            (item) =>
                                                                                item.id !== valueItem.id ||
                                                                                item.i !== valueItem.i
                                                                        );
                                                                        product.variants[i].values = newVariantValues;
                                                                        updateValueByKey(
                                                                            product,
                                                                            setProduct,
                                                                            "variants",
                                                                            product.variants
                                                                        );
                                                                    }}
                                                                />
                                                                {product.variants[i].values.length === iValue + 1 && (
                                                                    <IconButton
                                                                        appearance="primary"
                                                                        icon={<Plus />}
                                                                        style={{
                                                                            marginLeft: 10,
                                                                        }}
                                                                        onClick={() => {
                                                                            product.variants[i].values.push({
                                                                                i:
                                                                                    Math.max(
                                                                                        ...product.variants[i].values
                                                                                            .map((maxItem) => maxItem.i)
                                                                                            .filter(
                                                                                                (maxItem) => maxItem
                                                                                            ),
                                                                                        0
                                                                                    ) + 1,
                                                                                variant_id: "",
                                                                                value_id: "",
                                                                            });
                                                                            updateValueByKey(
                                                                                product,
                                                                                setProduct,
                                                                                "variants",
                                                                                product.variants
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : (
                                                    <IconButton
                                                        icon={<Plus />}
                                                        appearance="primary"
                                                        onClick={() => {
                                                            product.variants[i].values.push({
                                                                i: 0,
                                                                variant_id: "",
                                                                value_id: "",
                                                            });
                                                            updateValueByKey(
                                                                product,
                                                                setProduct,
                                                                "variants",
                                                                product.variants
                                                            );
                                                        }}
                                                    />
                                                )}

                                                {/* Stocks */}
                                                {stores.length > 0 && (
                                                    <>
                                                        <hr />
                                                        <h5>Stok məlumatları</h5>
                                                        <hr />
                                                        <ul
                                                            className="product-stock-lists"
                                                            style={{
                                                                margin: 0,
                                                                padding: 0,
                                                                listStyle: "none",
                                                            }}
                                                        >
                                                            {stores.map((store, index) => {
                                                                const stockIndex = product.stocks.findIndex(
                                                                    (stockItem) =>
                                                                        stockItem.store_id === store.id &&
                                                                        ((stockItem.product_variant_i === undefined &&
                                                                            stockItem.product_variant_id !== null &&
                                                                            stockItem.product_variant_id ===
                                                                                product.variants[i].id) ||
                                                                            (stockItem.product_variant_id ===
                                                                                undefined &&
                                                                                stockItem.product_variant_i ===
                                                                                    product.variants[i].i))
                                                                );
                                                                return (
                                                                    <li key={index}>
                                                                        <strong>{store.name.az}:</strong>
                                                                        <Input
                                                                            style={{ width: "auto" }}
                                                                            type="number"
                                                                            value={
                                                                                stockIndex > -1
                                                                                    ? product.stocks[stockIndex].stock
                                                                                    : 0
                                                                            }
                                                                            onChange={(value) => {
                                                                                let stocks = product.stocks;
                                                                                if (stockIndex === -1) {
                                                                                    stocks.push({
                                                                                        store_id: store.id,
                                                                                        product_variant_i:
                                                                                            product.variants[i].i,
                                                                                        stock: parseInt(value) || 0,
                                                                                    });
                                                                                } else {
                                                                                    stocks[stockIndex].stock =
                                                                                        parseInt(value) || 0;
                                                                                }
                                                                                updateValueByKey(
                                                                                    product,
                                                                                    setProduct,
                                                                                    "stocks",
                                                                                    stocks
                                                                                );
                                                                            }}
                                                                        />
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                        <hr />
                                                    </>
                                                )}
                                            </Grid>
                                        </Content>

                                        {/* Footer */}
                                        <Footer
                                            style={{ background: "#fff", padding: 10, boxShadow: "0 0 3px 0 #777" }}
                                        >
                                            <IconButton
                                                appearance="primary"
                                                color="red"
                                                icon={<Trash />}
                                                onClick={() => {
                                                    const newVariants = product.variants.filter(
                                                        (item) => item.id !== variant.id || item.i !== variant.i
                                                    );
                                                    product.variants = newVariants;
                                                    updateValueByKey(product, setProduct, "variants", product.variants);

                                                    // Delete stocks
                                                    const newStocks = product.stocks.filter(
                                                        (item) =>
                                                            item.product_variant_id !== variant.id ||
                                                            item.product_variant_i !== variant.i
                                                    );
                                                    product.stocks = newStocks;
                                                    updateValueByKey(product, setProduct, "stocks", product.stocks);

                                                    // Push deleted variants
                                                    if (variant.id) {
                                                        setDeletedVariants([...deletedVariants, variant.id]);
                                                    }
                                                }}
                                            />
                                            {product.variants.length === i + 1 && (
                                                <IconButton
                                                    appearance="primary"
                                                    icon={<Plus />}
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={() => {
                                                        const i =
                                                            Math.max(
                                                                ...product.variants
                                                                    .map((maxItem) => maxItem.i)
                                                                    .filter((maxItem) => maxItem),
                                                                0
                                                            ) + 1;
                                                        product.variants.push({
                                                            i,
                                                            stock_code: "",
                                                            price: 0,
                                                            discount_price: 0,
                                                            values: [],
                                                        });
                                                        updateValueByKey(
                                                            product,
                                                            setProduct,
                                                            "variants",
                                                            product.variants
                                                        );

                                                        // Push Stock
                                                        for (const store of stores) {
                                                            product.stocks.push({
                                                                store_id: store.id,
                                                                product_variant_i: i,
                                                                stock: 0,
                                                            });
                                                            updateValueByKey(
                                                                product,
                                                                setProduct,
                                                                "stocks",
                                                                product.stocks
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Footer>
                                    </div>
                                ))
                            ) : (
                                <IconButton
                                    icon={<Plus />}
                                    appearance="primary"
                                    onClick={() => {
                                        product.variants.push({
                                            i: 0,
                                            stock_code: "",
                                            price: 0,
                                            discount_price: 0,
                                            values: [],
                                        });
                                        updateValueByKey(product, setProduct, "variants", product.variants);

                                        // Push Stock
                                        for (const store of stores) {
                                            product.stocks.push({
                                                store_id: store.id,
                                                product_variant_i: 0,
                                                stock: 0,
                                            });
                                            updateValueByKey(product, setProduct, "stocks", product.stocks);
                                        }
                                    }}
                                />
                            )}
                        </div>

                        {/* Submit Button */}
                        <Content style={{ margin: 0 }}>
                            <SubmitButton text="Yadda saxla" />
                        </Content>
                    </Form>

                    {/* Loading */}
                    {loading && (
                        <Loader
                            size="md"
                            backdrop
                            center
                            content={loadingText}
                            style={{ position: "fixed", zIndex: 999 }}
                        />
                    )}
                </div>
            )}
        </>
    );
}
