import { useState } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema, Uploader } from "rsuite";
import { Image as IconImage } from "@rsuite/icons";
import Compressor from "compressorjs";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKeys,
} from "utils/utils";
import slug from "slug";

export default function BrandCreate() {
    const initialValue = {
        name: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        url: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        image: "",
    };
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [brand, setBrand] = useState(initialValue);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name", "url"];

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        url: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/brands`, brand);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setBrand(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     */
    const onRemoveImage = async (file) => {
        try {
            await axios.post("/brands/image/remove", {
                file: file,
            });
            setBrand({
                ...brand,
                image: "",
            });
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/brands">Markalar</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                    <Breadcrumb.Item active>Tək</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={brand}
                    model={formModel}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{ display: selectedLang === lang ? "block" : "none", marginBottom: 24 }}
                        >
                            <InputField
                                type="text"
                                name={`name-${lang}`}
                                label="Adı"
                                value={brand.name[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                onChange={(value) =>
                                    updateValueByKeys(brand, setBrand, {
                                        name: {
                                            ...brand.name,
                                            [lang]: value,
                                        },
                                        url: {
                                            ...brand.url,
                                            [lang]: slug(value),
                                        },
                                    })
                                }
                            />
                            <InputField
                                type="text"
                                name={`url-${lang}`}
                                label="URL"
                                value={brand.url[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                onChange={(value) => updateLangValueByKey(brand, setBrand, "url", lang, value)}
                            />
                            <div className="rs-form-control-wrapper">
                                <Form.Group>
                                    <Form.ControlLabel>
                                        Şəkil <br />
                                        <strong>Tövsiyyə olunan ölçülər: 205x95</strong>
                                    </Form.ControlLabel>
                                    <Uploader
                                        action={`${process.env.REACT_APP_API_URL}/brands/image/upload`}
                                        headers={{
                                            Authorization: localStorage.getItem("access_token")
                                                ? `Bearer ${localStorage.getItem("access_token")}`
                                                : "",
                                        }}
                                        accept="image/*"
                                        listType="picture"
                                        fileListVisible={false}
                                        onRemove={(file) => onRemoveImage(file.name)}
                                        onUpload={(file) => {
                                            setUploadLoading(true);
                                            if (brand.image) {
                                                onRemoveImage(brand.image);
                                            }
                                        }}
                                        onSuccess={(response, file) => {
                                            file.name = response.data;
                                            setBrand({
                                                ...brand,
                                                image: response.data,
                                            });
                                            setUploadLoading(false);
                                        }}
                                        onError={() => {
                                            setBrand({
                                                ...brand,
                                                image: "",
                                            });
                                            setUploadLoading(false);
                                        }}
                                        shouldUpload={(file) => {
                                            if (file.status !== "inited") {
                                                return false;
                                            }
                                            return new Promise((resolve, reject) => {
                                                new Compressor(file.blobFile, {
                                                    quality: 0.8,
                                                    success(result) {
                                                        file.blobFile = result;
                                                        resolve(true);
                                                    },
                                                    error(err) {
                                                        showToaster("danger", err.message);
                                                        reject(false);
                                                    },
                                                });
                                            });
                                        }}
                                        draggable
                                    >
                                        <button
                                            type="button"
                                            style={{
                                                width: 150,
                                                height: 150,
                                            }}
                                        >
                                            {uploadLoading && <Loader backdrop center />}
                                            {brand.image ? (
                                                <img
                                                    src={`${process.env.REACT_APP_SITE_URL}/storage/brands/${brand.image}`}
                                                    width="100%"
                                                    alt="brand"
                                                />
                                            ) : (
                                                <IconImage
                                                    style={{
                                                        fontSize: "5em",
                                                    }}
                                                />
                                            )}
                                        </button>
                                    </Uploader>
                                </Form.Group>
                            </div>
                        </div>
                    ))}
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
