import { useState, useEffect } from "react";
import { Header, Breadcrumb, IconButton, Table, Pagination, Button } from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import axios from "utils/axios";
import { useRouter } from "utils/hooks";

export default function Pages() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState([]);

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchPages(limit, page > 1 ? (page - 1) * limit : 0);
    }, [page, limit]);

    /**
     * Fetch Pages
     * @param limit 
     * @param offset 
     */
    const fetchPages = async (limit, offset) => {
        setLoading(true);
        const response = await axios.get(`/pages?limit=${limit}&offset=${offset}`);
        setPages(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Remove Page
     * @param id 
     */
    const removePage = async (id) => {
        if (window.confirm('Bunu etmək istədiyinizə əminsiniz?')) {
            try {
                await axios.delete(`/pages/${id}`);
                fetchPages(limit, page > 1 ? (page - 1) * limit : 0);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({
        rowData,
        dataKey,
        ...props
    }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton appearance="subtle" onClick={() => router.push(`pages/${rowData[dataKey]}/edit`)} icon={<Edit />} />
                <IconButton appearance="subtle" onClick={() => removePage(rowData[dataKey])} icon={<Trash />} />
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Səhifələr</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <Button appearance="primary" onClick={() => router.push('pages/create')}>Əlavə et</Button>
                </div>
                <Table
                    height={400}
                    rowHeight={60}
                    data={pages}
                    loading={loading}
                >
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Adı</Table.HeaderCell>
                        <Table.Cell>{rowData => rowData.name.az}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell dataKey="id" />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={['total', '-', 'limit', '|', 'pager']}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
};