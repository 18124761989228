import DefaultRoutes from "./default";
import AdminRoutes from "./admin";
import CategoryRoutes from "./category";
import BrandRoutes from "./brand";
import VariantRoutes from "./variant";
import ProductRoutes from "./product";
import PromotionRoutes from "./promotion";
import OrderRoutes from "./order";
import BannerRoutes from "./banner";
import BlogRoutes from "./blog";
import PageRoutes from "./page";
import ShippingPriceCityRoutes from "./shipping-prices/cities";
import ShippingPriceRegionRoutes from "./shipping-prices/regions";
import StoreRoutes from "./store";

export default [].concat.apply(
    [],
    [
        DefaultRoutes,
        AdminRoutes,
        CategoryRoutes,
        VariantRoutes,
        BrandRoutes,
        ProductRoutes,
        PromotionRoutes,
        OrderRoutes,
        BannerRoutes,
        BlogRoutes,
        PageRoutes,
        ShippingPriceCityRoutes,
        ShippingPriceRegionRoutes,
        StoreRoutes,
    ]
);
