import { useState, useEffect } from "react";
import {
    Header,
    Breadcrumb,
    IconButton,
    Table,
    Pagination,
    SelectPicker,
} from "rsuite";
import { Trash, Check, Close } from "@rsuite/icons";
import axios from "utils/axios";
import { dateFormat } from "utils/utils";

export default function OrderReviews() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        status: null,
    });
    const [reviews, setReviews] = useState([]);

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchReviews(limit, page > 1 ? (page - 1) * limit : 0, filter);
    }, [page, limit, filter]);

    /**
     * Fetch Reviews
     * @param limit
     * @param offset
     */
    const fetchReviews = async (limit, offset, filter) => {
        setLoading(true);
        const response = await axios.get(
            `/reviews?limit=${limit}&offset=${offset}${
                filter.status !== null ? `&status=${filter.status}` : ""
            }`
        );
        setReviews(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Remove Review
     * @param id
     */
    const removeReview = async (id) => {
        if (window.confirm("Bunu etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.delete(`/reviews/${id}`);
                fetchReviews(limit, page > 1 ? (page - 1) * limit : 0, filter);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Update Status
     * @param id
     * @param status
     */
    const updateReviewStatus = async (id, status) => {
        if (window.confirm("Bunu etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.post(`/reviews/${id}/status`, {
                    status: status,
                });
                fetchReviews(limit, page > 1 ? (page - 1) * limit : 0, filter);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                {rowData.status === 0 && (
                    <>
                        <IconButton
                            appearance="subtle"
                            onClick={() => updateReviewStatus(rowData.id, 1)}
                            icon={<Check />}
                        />
                        <IconButton
                            appearance="subtle"
                            onClick={() => updateReviewStatus(rowData.id, 2)}
                            icon={<Close />}
                        />
                    </>
                )}
                <IconButton
                    appearance="subtle"
                    onClick={() => removeReview(rowData.id)}
                    icon={<Trash />}
                />
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Rəylər</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <SelectPicker
                        placeholder="Status seç"
                        data={[
                            { label: "Hamısı", value: null },
                            { label: "Gözləyən", value: 0 },
                            { label: "Aktiv", value: 1 },
                            { label: "Deaktiv", value: 2 },
                        ]}
                        defaultValue={filter.status}
                        onChange={(value) =>
                            setFilter({ ...filter, status: value })
                        }
                    />
                </div>
                <Table
                    height={400}
                    rowHeight={60}
                    data={reviews}
                    loading={loading}
                >
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Rəy yazan</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) => rowData.user.name}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Rəy</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.review}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) =>
                                rowData.status === 1
                                    ? "Aktiv"
                                    : rowData.status === 2
                                    ? "Deaktiv"
                                    : "Gözləyən"
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Tarix</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) =>
                                dateFormat(
                                    new Date(rowData.created_at),
                                    "dd-MM-yyyy hh:mm:ss"
                                )
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "pager"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
