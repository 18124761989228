import { Message, toaster } from "rsuite";
import { SUPPORTED_LANGUAGES } from "appConstants";

/**
 * Show Toaster
 * @param {*} type
 * @param {*} message
 * @param {*} placement
 */
export const showToaster = (type, message, placement = "topEnd") => {
    if (Array.isArray(message)) {
        for (let i = 0; i < message.length; i++) {
            const msg = message[i];
            toaster.push(
                <Message duration={0} showIcon closable type={type}>
                    {msg}
                </Message>,
                {
                    placement: placement,
                }
            );
        }
    } else {
        toaster.push(
            <Message duration={0} showIcon closable type={type}>
                {message}
            </Message>,
            {
                placement: placement,
            }
        );
    }
};

/**
 * Delay
 * @param {*} fn
 * @param {*} ms
 * @returns
 */
export const delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

/**
 * Update Clipboard
 * @param {*} newClip
 */
export const updateClipboard = (newClip) => {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
            navigator.clipboard.writeText(newClip).then(
                function () {
                    showToaster("success", "Kopyalandı");
                },
                function () {
                    showToaster("danger", "Kopyalanma zamanı xəta baş verdi");
                }
            );
        }
    });
};

/**
 * Check Form Error for Language
 * @param {*} errors
 * @param {*} setFormErrors
 * @param {*} setSelectedLang
 * @param {*} checkKeys
 */
export const onCheckErrorLangForm = (
    errors,
    setFormErrors,
    setSelectedLang,
    checkKeys = []
) => {
    setFormErrors(errors);
    checkKeys.every((key) => {
        let status = true;
        SUPPORTED_LANGUAGES.every((lang) => {
            if (errors[key] && errors[key].object[lang].hasError) {
                setSelectedLang(lang);
                status = false;
                return false;
            }
            return true;
        });
        return status;
    });
};

/**
 * Get Form Error Message for Language
 * @param {*} errors
 * @param {*} key
 * @param {*} lang
 * @returns
 */
export const getLangFormErrMessage = (errors, key, lang) => {
    return errors[key] && errors[key].object[lang].hasError
        ? errors[key].object[lang].errorMessage || errors[key].object[lang]
        : null;
};

/**
 * Get Form Error Message
 * @param {*} errors
 * @param {*} key
 * @returns
 */
export const getFormErrMessage = (errors, key) => {
    return errors[key] ? errors[key].errorMessage || errors[key] : null;
};

/**
 * Update Language Value by Key
 * @param {*} state
 * @param {*} setState
 * @param {*} key
 * @param {*} lang
 * @param {*} value
 */
export const updateLangValueByKey = (state, setState, key, lang, value) => {
    setState({
        ...state,
        [key]: {
            ...state[key],
            [lang]: value,
        },
    });
};

/**
 * Update Value by Key
 * @param {*} state
 * @param {*} setState
 * @param {*} key
 * @param {*} value
 */
export const updateValueByKey = (state, setState, key, value) => {
    setState({
        ...state,
        [key]: value,
    });
};

/**
 * Update Value by Multiple Key
 * @param {*} state
 * @param {*} setState
 * @param {*} values
 */
export const updateValueByKeys = (state, setState, values) => {
    setState({
        ...state,
        ...values,
    });
};

/**
 * Find Nested Object
 * @param {*} entireObj
 * @param {*} keyToFind
 * @param {*} valToFind
 * @returns
 */
export const findNestedObj = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
};

/**
 * Check Role
 * @param {array} roles
 * @param {integer} role
 * @returns {bool}
 */
export const checkRole = (roles, role) => {
    return roles.length > 0 ? roles.includes(role) : true;
};

/**
 * Date Format
 * @param {Date} x
 * @param {string} y
 * @returns {string}
 */
export const dateFormat = (x, y) => {
    const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length);
    });
};
