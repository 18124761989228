import { Dashboard } from "@rsuite/icons";

const navigations = [
    {
        label: "Ana səhifə",
        icon: <Dashboard />,
        to: "/",
        roles: [],
    },
    {
        label: "Kateqoriyalar",
        icon: <Dashboard />,
        to: "/categories",
        roles: [0],
    },
    {
        label: "Markalar",
        icon: <Dashboard />,
        to: "/brands",
        roles: [0],
    },
    {
        label: "Məhsullar",
        icon: <Dashboard />,
        dropdowns: [
            {
                label: "Hamısı",
                to: "/products",
                roles: [0, 1],
            },
            {
                label: "Seçimlər",
                to: "/variants",
                roles: [0, 1],
            },
        ],
        roles: [0, 1],
    },
    {
        label: "Sifarişlər",
        icon: <Dashboard />,
        to: "/orders",
        roles: [0, 1],
    },
    {
        label: "Rəylər",
        icon: <Dashboard />,
        to: "/orders/reviews",
        roles: [0, 1],
    },
    {
        label: "Çatdırılma qiymətləri",
        icon: <Dashboard />,
        dropdowns: [
            {
                label: "Şəhərlər",
                to: "/shipping-prices/cities",
                roles: [0],
            },
            {
                label: "Rayonlar",
                to: "/shipping-prices/regions",
                roles: [0],
            },
        ],
        roles: [0],
    },
    {
        label: "Promolar",
        icon: <Dashboard />,
        to: "/promotions",
        roles: [0],
    },
    {
        label: "Bannerlər",
        icon: <Dashboard />,
        to: "/banners",
        roles: [],
    },
    {
        label: "Bloqlar",
        icon: <Dashboard />,
        to: "/blogs",
        roles: [0],
    },
    {
        label: "Səhifələr",
        icon: <Dashboard />,
        to: "/pages",
        roles: [0],
    },
    {
        label: "Mağazalarımız",
        icon: <Dashboard />,
        to: "/stores",
        roles: [0],
    },
    {
        label: "Adminlər",
        icon: <Dashboard />,
        to: "/admins",
        roles: [0],
    },
];

export default navigations;
