import { Header, Content, Breadcrumb } from "rsuite";

export default function Home() {
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>Admin panelə xoş gəlmisiniz</Content>
        </>
    );
};