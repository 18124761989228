import { useState, useEffect } from "react";
import {
    Header,
    Breadcrumb,
    IconButton,
    Table,
    Pagination,
    Button,
} from "rsuite";
import { Edit, Trash } from "@rsuite/icons";
import axios from "utils/axios";
import { useRouter } from "utils/hooks";
import { BANNER_TYPES } from "appConstants";

export default function Banners() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [banners, setBanners] = useState([]);

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchBanners(limit, page > 1 ? (page - 1) * limit : 0);
    }, [page, limit]);

    /**
     * Fetch Banners
     * @param limit
     * @param offset
     */
    const fetchBanners = async (limit, offset) => {
        setLoading(true);
        const response = await axios.get(
            `/banners?limit=${limit}&offset=${offset}`
        );
        setBanners(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Remove Banner
     * @param id
     */
    const removeBanner = async (id) => {
        if (window.confirm("Bunu etmək istədiyinizə əminsiniz?")) {
            try {
                await axios.delete(`/banners/${id}`);
                fetchBanners(limit, page > 1 ? (page - 1) * limit : 0);
            } catch (err) {
                console.log(err);
            }
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() =>
                        router.push(`banners/${rowData[dataKey]}/edit`)
                    }
                    icon={<Edit />}
                />
                <IconButton
                    appearance="subtle"
                    onClick={() => removeBanner(rowData[dataKey])}
                    icon={<Trash />}
                />
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Bannerlər</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <Button
                        appearance="primary"
                        onClick={() => router.push("banners/create")}
                    >
                        Əlavə et
                    </Button>
                </div>
                <Table
                    height={400}
                    rowHeight={60}
                    data={banners}
                    loading={loading}
                >
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Növü</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) =>
                                BANNER_TYPES.find(
                                    (item) => item.value === rowData.type
                                ).label
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Sıra</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) =>
                                rowData.type === 2
                                    ? rowData.row + 1
                                    : rowData.row
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell dataKey="id" />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "pager"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
