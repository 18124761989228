import { useState, useEffect } from "react";
import {
    Header,
    Breadcrumb,
    IconButton,
    Table,
    Pagination,
    Button,
    Input,
    InputGroup,
    Badge,
    SelectPicker,
} from "rsuite";
import { Edit, Search } from "@rsuite/icons";
import FaIcon from "components/faIcon";
import * as faStarRegular from "@fortawesome/free-regular-svg-icons/faStar";
import * as faStarSolid from "@fortawesome/free-solid-svg-icons/faStar";
import axios from "utils/axios";
import { useRouter } from "utils/hooks";
import { delay } from "utils/utils";
import { PRODUCT_DEACTIVE_REASONS } from "appConstants";

export default function Products() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        query: "",
        status: null,
        // reason_deactive: null,
        store: null,
    });
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]);

    /**
     * Fetch Products
     * @param limit
     * @param offset
     */
    const fetchProducts = async (limit, offset, filter) => {
        setLoading(true);
        const response = await axios.get(
            `/products?limit=${limit}&offset=${offset}${filter.query ? `&query=${filter.query}` : ""}&status=${
                filter.status
            }${filter.store !== null ? `&store=${filter.store}` : ""}`
        );
        setProducts(response.data.data.lists);
        setTotal(response.data.data.total);
        setLoading(false);
    };

    /**
     * Fetch Stores
     */
    const fetchStores = async () => {
        const response = await axios.get(`/stores`);
        setStores(
            response.data.data.lists.map((item) => ({
                label: item.name.az,
                value: item.id,
            }))
        );
    };

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchStores();
    }, []);

    /**
     * If Change Page and Limit
     */
    useEffect(() => {
        fetchProducts(limit, page > 1 ? (page - 1) * limit : 0, filter);
    }, [page, limit, filter]);

    /**
     * Update Featured
     * @param id
     */
    const updateFeatured = async (id) => {
        try {
            await axios.post(`/products/${id}/featured`);
            fetchProducts(limit, page > 1 ? (page - 1) * limit : 0, filter);
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Table Action Cell Component
     */
    const ActionCell = ({ rowData, ...props }) => {
        return (
            <Table.Cell {...props} className="link-group">
                <IconButton
                    appearance="subtle"
                    onClick={() => router.push(`products/${rowData.id}/edit`)}
                    icon={<Edit />}
                />
                <IconButton
                    appearance="subtle"
                    onClick={() => updateFeatured(rowData.id)}
                    icon={rowData.is_featured ? <FaIcon icon={faStarSolid} /> : <FaIcon icon={faStarRegular} />}
                />
            </Table.Cell>
        );
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item active>Məhsullar</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <div id="table-container">
                <div className="table-header-options">
                    <InputGroup size="md" inside style={{ width: 200, marginRight: 15 }}>
                        <Input
                            placeholder="Axtar..."
                            onChange={(value) => {
                                delay(() => {
                                    if (page > 1) {
                                        setPage(1);
                                    }
                                    setFilter({
                                        ...filter,
                                        query: value,
                                    });
                                }, 250);
                            }}
                        />
                        <InputGroup.Button>
                            <Search />
                        </InputGroup.Button>
                    </InputGroup>
                    <SelectPicker
                        style={{ marginRight: 15 }}
                        placeholder="Mağaza seç"
                        data={stores}
                        onChange={(value) => setFilter({ ...filter, store: value })}
                    />
                    <SelectPicker
                        style={{ marginRight: 15 }}
                        placeholder="Status seç"
                        data={[
                            { label: "Aktiv", value: 1 },
                            { label: "Deaktiv", value: 0 },
                            // ...PRODUCT_DEACTIVE_REASONS.map((item) => ({
                            //     label: item.label,
                            //     value: 0,
                            //     reason_deactive: item.value,
                            // })),
                        ]}
                        onSelect={(value, item) => setFilter({ ...filter, status: value })}
                    />
                    <Button appearance="primary" onClick={() => router.push("products/create")}>
                        Əlavə et
                    </Button>
                    {/* <Dropdown
                        renderToggle={(props, ref) => (
                            <Button {...props} ref={ref} appearance="primary">
                                Əlavə et <ArrowDown />
                            </Button>
                        )}
                    >
                        <Dropdown.Item
                            onClick={() => router.push("products/create")}
                        >
                            Tək
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => router.push("products/create/bulk")}
                        >
                            Toplu
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                router.push("products/create/bulk/images")
                            }
                        >
                            Şəkillər
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                router.push("products/create/bulk/descriptions")
                            }
                        >
                            Təsvirlər
                        </Dropdown.Item>
                    </Dropdown> */}
                </div>
                <Table height={400} rowHeight={60} data={products} loading={loading}>
                    <Table.Column width={70} fixed>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.Cell dataKey="id" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Stok Kodu</Table.HeaderCell>
                        <Table.Cell dataKey="stock_code" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Bar Kodu</Table.HeaderCell>
                        <Table.Cell dataKey="stock_bar_code" />
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Adı</Table.HeaderCell>
                        <Table.Cell>{(rowData) => rowData.name.az}</Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData) =>
                                rowData.is_active ? (
                                    <Badge content="Aktiv" color="green" />
                                ) : (
                                    <>
                                        <Badge
                                            content="Deaktiv"
                                            style={{
                                                display: rowData.reason_deactive !== null ? "table" : "inline-block",
                                            }}
                                        />
                                        {(() => {
                                            const reasonDeactive = PRODUCT_DEACTIVE_REASONS.find(
                                                (item) => item.value === rowData.reason_deactive
                                            );
                                            return (
                                                reasonDeactive && <Badge content={reasonDeactive.label} color="blue" />
                                            );
                                        })()}
                                    </>
                                )
                            }
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={1} fixed>
                        <Table.HeaderCell>Əməliyyatlar</Table.HeaderCell>
                        <ActionCell />
                    </Table.Column>
                </Table>
                <div style={{ padding: 20 }}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="md"
                        layout={["total", "-", "limit", "|", "pager"]}
                        total={total}
                        limitOptions={[10, 20, 30, 40, 50, 100, 200]}
                        limit={limit}
                        activePage={page}
                        onChangePage={(page) => setPage(page > 0 ? page : 1)}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
