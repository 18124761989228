import Regions from "pages/shipping-prices/regions";
import RegionCreate from "pages/shipping-prices/regions/create";
import RegionEdit from "pages/shipping-prices/regions/edit";

const RegionRoutes = [
    {
        path: '/shipping-prices/regions',
        component: Regions,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/shipping-prices/regions/:id/edit',
        component: RegionEdit,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/shipping-prices/regions/create',
        component: RegionCreate,
        private: true,
        privateRoles: [0],
    },
];

export default RegionRoutes;