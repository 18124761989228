import { Form, Button, Input, InputGroup } from "rsuite";

export const InputField = (props) => {
    const { name, label, accepter, textarea, group, groupAddon, ...rest } = props;
    const input = textarea ? <Input block="true" accepter={accepter} as="textarea" {...rest} /> : <Form.Control block={accepter ? true : 'true'} name={name} accepter={accepter} {...rest} />;
    return (
        <Form.Group controlId={name}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            {group ? (
                <InputGroup style={{ width: '100%' }}>
                    <InputGroup.Addon>{groupAddon}</InputGroup.Addon>
                    {input}
                </InputGroup>
            ) : input}
        </Form.Group>
    );
};

export const SubmitButton = (props) => {
    const { text, ...rest } = props;
    return (
        <Form.Group>
            <Button type="submit" appearance="primary" {...rest}>{text}</Button>
        </Form.Group>
    );
};