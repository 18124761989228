import { useState, useEffect, useCallback } from "react";
import {
    Header,
    Content,
    Breadcrumb,
    Nav,
    Loader,
    Form,
    SelectPicker,
    Schema,
    Grid,
    Row,
    Col,
    IconButton,
} from "rsuite";
import { Trash, Plus } from "@rsuite/icons";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
} from "utils/utils";

export default function RegionCreate() {
    const initialValue = {
        name: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        city_id: 0,
        prices: [
            {
                i: 0,
                order_min_price: 0,
                order_max_price: 0,
                price: 0,
            },
        ],
    };
    const [loading, setLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [region, setRegion] = useState(initialValue);
    const [cities, setCities] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["name"];

    /**
     * Fetch Cities
     */
    const fetchCities = useCallback(async () => {
        const response = await axios.get(`/shipping-prices/cities`);
        setCities(
            response.data.data.lists.map((item) => ({
                label: item.name.az,
                value: item.id,
            }))
        );
    }, []);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        name: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        city_id: Schema.Types.NumberType().addRule((value, data) => value !== 0, "Bu xananın doldurulması məcburidir."),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/shipping-prices/regions`, region);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setRegion(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/shipping-prices/regions">Çatdırılma qiymətləri (Rayonlar)</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={region}
                    model={formModel}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <div
                            key={i}
                            id={`tabs-${lang}`}
                            style={{
                                display: selectedLang === lang ? "block" : "none",
                                marginBottom: 24,
                            }}
                        >
                            <InputField
                                type="text"
                                name={`name-${lang}`}
                                label="Adı"
                                value={region.name[lang]}
                                errorMessage={getLangFormErrMessage(formErrors, "name", lang)}
                                onChange={(value) => updateLangValueByKey(region, setRegion, "name", lang, value)}
                            />
                        </div>
                    ))}
                    <InputField
                        accepter={SelectPicker}
                        value={region.city_id}
                        name="city_id"
                        label="Şəhər"
                        data={cities}
                        onChange={(value) => updateValueByKey(region, setRegion, "city_id", parseInt(value) || 0)}
                    />
                    <div id="prices" style={{ marginBottom: 24 }}>
                        <h5>Çatdırılma qiymətləri</h5>
                        <Grid fluid style={{ padding: 0, marginTop: 24 }}>
                            {region.prices.length > 0 ? (
                                region.prices.map((value, i) => (
                                    <Row key={i} style={{ marginBottom: 24 }}>
                                        <Col xs={7}>
                                            <InputField
                                                type="number"
                                                step="any"
                                                name={`order_min_price-${i}`}
                                                label="Minimum sifariş qiyməti"
                                                value={region.prices[i].order_min_price}
                                                onChange={(value) => {
                                                    region.prices[i].order_min_price = parseFloat(value) || 0;
                                                    updateValueByKey(region, setRegion, "prices", region.prices);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={7}>
                                            <InputField
                                                type="number"
                                                step="any"
                                                name={`order_max_price-${i}`}
                                                label="Maksimum sifariş qiyməti"
                                                value={region.prices[i].order_max_price}
                                                onChange={(value) => {
                                                    region.prices[i].order_max_price = parseFloat(value) || 0;
                                                    updateValueByKey(region, setRegion, "prices", region.prices);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={7}>
                                            <InputField
                                                type="number"
                                                step="any"
                                                name={`price-${i}`}
                                                label="Çatdırılma qiyməti"
                                                value={region.prices[i].price}
                                                onChange={(value) => {
                                                    region.prices[i].price = parseFloat(value) || 0;
                                                    updateValueByKey(region, setRegion, "prices", region.prices);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={3} style={{ marginTop: 24 }}>
                                            <IconButton
                                                icon={<Trash />}
                                                onClick={() =>
                                                    updateValueByKey(
                                                        region,
                                                        setRegion,
                                                        "prices",
                                                        region.prices.filter((item) => item.i !== i)
                                                    )
                                                }
                                            />
                                            {region.prices.length === i + 1 && (
                                                <IconButton
                                                    icon={<Plus />}
                                                    style={{ marginLeft: 10 }}
                                                    onClick={() => {
                                                        region.prices.push({
                                                            i:
                                                                Math.max(
                                                                    ...region.prices
                                                                        .map((maxItem) => maxItem.i)
                                                                        .filter((maxItem) => maxItem),
                                                                    0
                                                                ) + 1,
                                                            order_min_price: 0,
                                                            order_max_price: 0,
                                                            price: 0,
                                                        });
                                                        updateValueByKey(region, setRegion, "prices", region.prices);
                                                    }}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                ))
                            ) : (
                                <IconButton
                                    icon={<Plus />}
                                    onClick={() => {
                                        region.prices.push({
                                            i: 0,
                                            order_min_price: 0,
                                            order_max_price: 0,
                                            price: 0,
                                        });
                                        updateValueByKey(region, setRegion, "prices", region.prices);
                                    }}
                                />
                            )}
                        </Grid>
                    </div>
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
