import Banners from "pages/banners";
import BannerCreate from "pages/banners/create";
import BannerEdit from "pages/banners/edit";

const BannerRoutes = [
    {
        path: "/banners",
        component: Banners,
        private: true,
        privateRoles: [],
    },
    {
        path: "/banners/:id/edit",
        component: BannerEdit,
        private: true,
        privateRoles: [],
    },
    {
        path: "/banners/create",
        component: BannerCreate,
        private: true,
        privateRoles: [],
    },
];

export default BannerRoutes;
