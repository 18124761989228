import Stores from "pages/stores";
import StoreEdit from "pages/stores/edit";

const StoreRoutes = [
    {
        path: '/stores',
        component: Stores,
        private: true,
        privateRoles: [0],
    },
    {
        path: '/stores/:id/edit',
        component: StoreEdit,
        private: true,
        privateRoles: [0],
    },
];

export default StoreRoutes;