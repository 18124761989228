import { useCallback, useEffect, useState } from "react";
import { Header, Content, Breadcrumb, Loader, Form, SelectPicker, Schema } from "rsuite";
import { useParams } from "react-router-dom";
import { InputField, SubmitButton } from "components/form";
import NotFound from "components/notFound";
import axios from "utils/axios";
import { showToaster, updateValueByKey } from "utils/utils";

export default function OrderEdit() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingText, setLoadingText] = useState(null);
    const [order, setOrder] = useState(null);

    /**
     * Status Types
     */
    const statusTypes = [
        {
            label: "Sifariş hazırlanır",
            value: 1,
        },
        {
            label: "Sifariş çatdırılmadadır",
            value: 2,
        },
        {
            label: "Sifariş tamamlandı",
            value: 3,
        },
        {
            label: "Sifariş ləğv edildi",
            value: 4,
        },
    ];

    /**
     * Fetch Order
     */
    const fetchOrder = useCallback(async () => {
        try {
            const response = await axios.get(`/orders/${id}`);
            setOrder(response.data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setOrder(undefined);
        }
    }, [id]);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchOrder();
    }, [fetchOrder]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        status_type: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setLoadingText("Yadda saxlanılır...");
        setLoading(true);
        try {
            const response = await axios.post(`/orders/${id}`, {
                status_type: order.status_type,
            });
            if (response.data.success) {
                showToaster("success", "Yadda saxlanıldı");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/orders">Sifarişlər</Breadcrumb.Item>
                    <Breadcrumb.Item active>Redaktə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            {order === undefined && (
                <Content>
                    <NotFound />
                </Content>
            )}
            {order && (
                <Form fluid onSubmit={onSubmit} formValue={order} model={formModel}>
                    <Content>
                        <InputField
                            accepter={SelectPicker}
                            name="status_type"
                            label="Status"
                            data={statusTypes}
                            defaultValue={order.status_type}
                            disabledItemValues={[1]}
                            onChange={(value) => updateValueByKey(order, setOrder, "status_type", value)}
                        />
                    </Content>
                    <Content>
                        <SubmitButton text="Yadda saxla" />
                    </Content>
                    {loading && (
                        <Loader
                            style={{ zIndex: 99, position: "fixed" }}
                            size="md"
                            backdrop
                            center
                            content={loadingText || "Zəhmət olmasa gözləyin..."}
                        />
                    )}
                </Form>
            )}
        </>
    );
}
