import { useState, useCallback, useEffect } from "react";
import { Header, Content, Breadcrumb, Nav, Loader, Form, Schema, SelectPicker, Uploader } from "rsuite";
import { Image as IconImage } from "@rsuite/icons";
// import Compressor from "compressorjs";
import { InputField, SubmitButton } from "components/form";
import { SUPPORTED_LANGUAGES, BANNER_TYPES } from "appConstants";
import axios from "utils/axios";
import {
    getLangFormErrMessage,
    onCheckErrorLangForm,
    showToaster,
    updateLangValueByKey,
    updateValueByKey,
    updateValueByKeys,
} from "utils/utils";

export default function BannerCreate() {
    const initialValue = {
        image: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        url: Object.fromEntries(SUPPORTED_LANGUAGES.map((lang) => [lang, ""])),
        cat_id: "",
        row: 0,
        type: 0,
    };
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [selectedLang, setSelectedLang] = useState("az");
    const [banner, setBanner] = useState(initialValue);
    const [currentBannerType, setCurrentBannerType] = useState(BANNER_TYPES[0]);
    const [categories, setCategories] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const FORM_CHECK_LANGUAGE_KEYS = ["image", "url"];

    /**
     * Fetch Categories
     */
    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get(`/categories?parent_id=null`);
            setCategories(
                response.data.data.lists.map((item) => ({
                    label: item.name.az,
                    value: item.id,
                }))
            );
        } catch (err) {
            setCategories([]);
        }
    }, []);

    /**
     * First Open Page
     */
    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    /**
     * Onchange Banner Type
     */
    useEffect(() => {
        setCurrentBannerType(BANNER_TYPES.find((item) => item.value === banner.type));
    }, [banner]);

    /**
     * Initialize Form Schema Validation
     */
    const formModel = Schema.Model({
        image: Schema.Types.ObjectType().shape(
            Object.fromEntries(
                SUPPORTED_LANGUAGES.map((lang) => [
                    lang,
                    Schema.Types.StringType().isRequired("Bu xananın doldurulması məcburidir."),
                ])
            )
        ),
        // url: Schema.Types.ObjectType().shape(
        //     Object.fromEntries(
        //         SUPPORTED_LANGUAGES.map((lang) => [
        //             lang,
        //             Schema.Types.StringType()
        //                 .isRequired("Bu xananın doldurulması məcburidir.")
        //                 .isURL("Format düzgün deyil"),
        //         ])
        //     )
        // ),
        row: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
        type: Schema.Types.NumberType().isRequired("Bu xananın doldurulması məcburidir."),
    });

    /**
     * Send Request and Save Data
     * @param {*} checkStatus
     * @param {*} event
     * @returns
     */
    const onSubmit = async (checkStatus, event) => {
        if (loading || !checkStatus) {
            return;
        }
        setFormErrors({});
        setLoading(true);
        try {
            const response = await axios.post(`/banners`, banner);
            if (response.data.success) {
                showToaster("success", "Əlavə olundu");
            }
            setBanner(initialValue);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * Remove Image
     * @param file
     * @param lang
     */
    const onRemoveImage = async (file, lang) => {
        try {
            await axios.post("/banners/image/remove", {
                file: file,
            });
            updateLangValueByKey(banner, setBanner, "image", lang, "");
        } catch (err) {
            showToaster("error", err.response.data.message);
        }
    };

    /**
     * onShouldUpload
     * @param {import("rsuite/esm/Uploader/Uploader").FileType} file
     * @returns {boolean}
     */
    const onShouldUpload = async (file) => {
        if (file.status !== "inited") {
            return false;
        }

        /**
         * Check And Compress Image
         */
        try {
            await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.blobFile);
                reader.onload = function () {
                    const img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        const bannerSizes = currentBannerType.children
                            ? currentBannerType.children[banner.row].sizes
                            : currentBannerType.sizes;
                        if (img.width !== bannerSizes.width || img.height !== bannerSizes.height) {
                            file.status = "error";
                            reject("Zəhmət olmasa göstərilən ölçüdə yükləyin");
                        } else {
                            resolve();
                        }
                    };
                };
            });

            // compress image
            // await new Promise((resolve, reject) => {
            //     new Compressor(file.blobFile, {
            //         quality: 0.8,
            //         success(result) {
            //             file.blobFile = result;
            //             resolve();
            //         },
            //         error(err) {
            //             file.status = "error";
            //             reject(err.message);
            //         },
            //     });
            // });

            // return success
            return true;
        } catch (error) {
            showToaster("error", error);
            return false;
        }
    };

    /**
     * Render
     */
    return (
        <>
            <Header>
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Ana səhifə</Breadcrumb.Item>
                    <Breadcrumb.Item href="/banners">Bannerlər</Breadcrumb.Item>
                    <Breadcrumb.Item active>Əlavə et</Breadcrumb.Item>
                </Breadcrumb>
            </Header>
            <Content>
                <Nav
                    appearance="tabs"
                    activeKey={selectedLang}
                    onSelect={(key) => setSelectedLang(key)}
                    style={{ marginBottom: 20 }}
                >
                    {SUPPORTED_LANGUAGES.map((lang, i) => (
                        <Nav.Item key={i} eventKey={lang}>
                            {lang.toUpperCase()}
                        </Nav.Item>
                    ))}
                </Nav>
                <Form
                    fluid
                    onSubmit={onSubmit}
                    onError={(errors) =>
                        onCheckErrorLangForm(errors, setFormErrors, setSelectedLang, FORM_CHECK_LANGUAGE_KEYS)
                    }
                    formValue={banner}
                    model={formModel}
                >
                    <InputField
                        accepter={SelectPicker}
                        name="type"
                        label="Növü"
                        data={BANNER_TYPES}
                        onChange={(value) =>
                            updateValueByKeys(banner, setBanner, {
                                type: value,
                                cat_id: value !== 3 ? "" : banner.cat_id,
                                row: 0,
                            })
                        }
                    />
                    {currentBannerType.children && (
                        <InputField
                            accepter={SelectPicker}
                            value={banner.row}
                            label="Banner"
                            data={currentBannerType.children}
                            onChange={(value) => updateValueByKey(banner, setBanner, "row", parseInt(value) || 0)}
                        />
                    )}
                    {banner.type === 3 && categories.length > 0 && (
                        <InputField
                            accepter={SelectPicker}
                            name="cat_id"
                            label="Kateqoriya"
                            data={categories}
                            onChange={(value) => updateValueByKey(banner, setBanner, "cat_id", value)}
                        />
                    )}
                    {SUPPORTED_LANGUAGES.map((lang, i) => {
                        const bannerSizes = currentBannerType.children
                            ? currentBannerType.children[banner.row].sizes
                            : currentBannerType.sizes;
                        return (
                            <div
                                key={i}
                                id={`tabs-${lang}`}
                                style={{
                                    display: selectedLang === lang ? "block" : "none",
                                    marginBottom: 24,
                                }}
                            >
                                <div className="rs-form-control-wrapper">
                                    <Form.Group>
                                        <Form.ControlLabel>
                                            Şəkil (
                                            <strong>
                                                {bannerSizes.width}x{bannerSizes.height}
                                            </strong>
                                            )
                                        </Form.ControlLabel>
                                        <Uploader
                                            action={`${process.env.REACT_APP_API_URL}/banners/image/upload`}
                                            headers={{
                                                Authorization: localStorage.getItem("access_token")
                                                    ? `Bearer ${localStorage.getItem("access_token")}`
                                                    : "",
                                            }}
                                            accept="image/*"
                                            listType="picture"
                                            fileListVisible={false}
                                            onRemove={(file) => onRemoveImage(file.name, lang)}
                                            onUpload={(file) => {
                                                setUploadLoading(true);
                                                if (banner.image[lang]) {
                                                    onRemoveImage(banner.image[lang], lang);
                                                }
                                            }}
                                            onSuccess={(response, file) => {
                                                file.name = response.data;
                                                updateLangValueByKey(banner, setBanner, "image", lang, response.data);
                                                setUploadLoading(false);
                                            }}
                                            onError={() => {
                                                updateLangValueByKey(banner, setBanner, "image", lang, "");
                                                setUploadLoading(false);
                                            }}
                                            shouldUpload={onShouldUpload}
                                            draggable
                                        >
                                            <button
                                                type="button"
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                }}
                                            >
                                                {uploadLoading && <Loader backdrop center />}
                                                {banner.image[lang] ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_SITE_URL}/storage/banners/${banner.image[lang]}`}
                                                        width="100%"
                                                        alt="banner"
                                                    />
                                                ) : (
                                                    <IconImage
                                                        style={{
                                                            fontSize: "5em",
                                                        }}
                                                    />
                                                )}
                                            </button>
                                        </Uploader>
                                        <Form.ErrorMessage
                                            show={getLangFormErrMessage(formErrors, "image", lang) ? true : false}
                                            placement="bottomStart"
                                        >
                                            {getLangFormErrMessage(formErrors, "image", lang)}
                                        </Form.ErrorMessage>
                                    </Form.Group>
                                </div>
                                <InputField
                                    type="url"
                                    name={`url-${lang}`}
                                    label="URL"
                                    value={banner.url[lang]}
                                    errorMessage={getLangFormErrMessage(formErrors, "url", lang)}
                                    onChange={(value) => updateLangValueByKey(banner, setBanner, "url", lang, value)}
                                />
                            </div>
                        );
                    })}
                    {banner.type !== 2 && banner.type !== 3 && (
                        <InputField
                            type="number"
                            name="row"
                            label="Sıra"
                            onChange={(value) => updateValueByKey(banner, setBanner, "row", parseInt(value) || 0)}
                        />
                    )}
                    <SubmitButton text="Əlavə et" />
                </Form>
                {loading && <Loader size="md" backdrop center content="Yadda saxlanılır..." />}
            </Content>
        </>
    );
}
